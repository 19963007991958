import React from "react";
// import ResultBanner from '../srtst/Result/ResultBanner'
import GalleryBanner from "./GalleryBanner";
import Navbar from "../srtst/Navbar/Navbar";
import FooterComponent from "../home/Footer/Footer";
import VideoGallery from "./VideoGallery";

const Video = () => {
  return (
    <div>
      <Navbar />
      <GalleryBanner />
      <VideoGallery />
      <FooterComponent />
    </div>
  );
};
export default Video;
