import React from 'react';
import FooterComponent from '../home/Footer/Footer';
import Navbar from '../srtst/Navbar/Navbar';
import SrtstFaq from './FaqSrtst'
import SrtstFaqData from '../../json/SrtstFaqData'
import BenefitSrtst from './BenefitSrtst'
import WhatisSrtst from './WhatisSrtst'
import SrtstSyllabus from './SrtstSyllabus'
import Banner from './Banner/Banner';
import Testimonialpage from './Testimonialpage';
import BannerSection from './bannerSection';

const SrtstPage = () => {
    return (
        <>
            <Navbar />
            <Banner />
            <BenefitSrtst />
            <WhatisSrtst />

            <BannerSection />
            <SrtstSyllabus />
            <Testimonialpage />
            <SrtstFaq faqData={SrtstFaqData} />
            <FooterComponent />
        </>
    )

}

export default SrtstPage