import { Box, IconButton } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ResultBanner1 from "../../assets/Images/resultbanner1.jpg";
import ResultBanner2 from "../../assets/Images/resultbanner2.jpg";

const BannerSection = () => {
  const items = [
    {
      name: ResultBanner1,
    },
    {
      name: ResultBanner2,
    },
  ];

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: "transparent",
          color: "transparent",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // cursor: "pointer",
        }}
        onClick={onClick}
      >
        <IconButton
          onClick={onClick}
          style={{
            position: "absolute",
            left: "10px",
            top: "50%",
            justifyContent: "center",
            transform: "translateY(-50%)",
            backgroundColor: "black",
            borderRadius: "100%",
            padding: "5px",
          }}
        >
          <ArrowForwardIosIcon style={{ color: "white" }} />
        </IconButton>
      </div>
    );
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        padding: { xs: "20px 0", sm: "30px 0", md: "50px 0", lg: "80px 0" },
        mx: {
          md: "25px",
          sm: "30px",
          xs: "15px",
          lg: "90px",
        },
        backgroundColor: "#ffffff",
      }}
    >
      <Slider {...settings}>
        {items.map((item, i) => (
          <div style={{ width: "100%", height: "auto" }}>
            <Item key={i} item={item} />
          </div>
        ))}
      </Slider>
    </Box>
  );
};

function Item(props) {
  return (
    <Box
      sx={{
        height: {
          xs: "30vh",
          sm: "60vh",
          md: "70vh",
        },
        backgroundImage: `url('${props.item.name}')`,
        objectFit: "contain",
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: 8,
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        flexShrink: 0,
        backgroundColor: "black",
        // mx:'100px',
        // my:'60px'
      }}
    ></Box>
  );
}

export default BannerSection;
