import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import OurProgramCard from '../../components/cardcomponents/OurProgramCard'
import OurProgramData from '../../json/OurProgramData'

const OurProgram = () => {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "Column",
            margin: "50px 0px",
        }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                    variant="h4"
                    sx={{ fontSize: "28px", color: "#000000", fontWeight: "600" }}
                >
                    Our Programmes
                </Typography>

            </Box>
            <Grid container spacing={-20} mt={"40px"}>
                {OurProgramData?.map((item) => (
                    <Grid
                        item
                        md={6}
                        sm={6}
                        xs={12}
                        sx={{
                            display: "flex",
                            // justifyContent: "center",
                            alignItems: "center",
                            margin: { sm: "10px 0", xs: "10px 0" },
                        }}
                    >
                        <OurProgramCard data={item} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default OurProgram;
