import React, { useRef } from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "./style.css";
import satyaLogo from "../../../assets/Images/satyalogo.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function ResultTable(props) {
  const { results } = props;
  const reportTemplateRef = useRef(null);
  const resultDownload = () => {
    const input = document.getElementById("result-pdf");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const margin = 10;

      pdf.addImage(
        imgData,
        "PNG",
        margin,
        margin,
        imgWidth - 2 * margin,
        imgHeight - 2 * margin
      );
      pdf.save("result-download.pdf");
    });
  };

  return (
    <>
      {results?.data === null ? (
        <Stack
          sx={{
            mt: 3,
            mb: 3,
            maxWidth: "100%",
          }}
        >
          <Container>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{ fontWeight: "600", color: "red", fontSize: "16px" }}
              >
                {results?.message}
              </Typography>
            </Box>
          </Container>
        </Stack>
      ) : (
        <Stack
          sx={{
            pt: 7,
            pb: 7,
            mt: 3,
            maxWidth: "100%",
          }}
        >
          <Container>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
              }}
            >
              <Button variant="contained" onClick={resultDownload}>
                Download
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                columnGap: "40px",
                rowGap: "10px",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                mt: 3,
                paddingTop: "10px",
              }}
              id="result-pdf"
            >
              <table ref={reportTemplateRef}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontWeight: "600",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                      colSpan={2}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img src={satyaLogo} alt="logo" width="220px" />
                          <Typography
                            sx={{
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: { xs: "15px", sm: "20px", md: "28px" },
                              lineHeight: {
                                xs: "25px",
                                sm: "35px",
                                md: "45px",
                              },
                              fontWeight: 600,
                              textAlign: "center",
                              mt: 2,
                            }}
                          >
                            Shri Radhey Shyam Tripathi Scholarship Test
                          </Typography>
                        </Box>
                        <Typography
                          variant="span"
                          sx={{
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: { xs: "15px", sm: "20px", md: "28px" },
                            lineHeight: { xs: "25px", sm: "35px", md: "45px" },
                            fontWeight: 600,
                            textAlign: "center",
                            color: "#FF8331",
                          }}
                        >
                          SRTST 2024
                        </Typography>
                      </Box>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "600",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      Name
                    </td>
                    <td style={{ fontFamily: "'Poppins', sans-serif" }}>
                      {results?.data?.FULL_NAME}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "600",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      Roll No
                    </td>
                    <td style={{ fontFamily: "'Poppins', sans-serif" }}>
                      {results?.data?.ROLL_NUMBER}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "600",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      School
                    </td>
                    <td style={{ fontFamily: "'Poppins', sans-serif" }}>
                      {results?.data?.SCHOOL_NAME}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "600",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      Block
                    </td>
                    <td style={{ fontFamily: "'Poppins', sans-serif" }}>
                      {results?.data?.BLOCK}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "600",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    >
                      Rank
                    </td>
                    <td style={{ fontFamily: "'Poppins', sans-serif" }}>
                      {results?.data?.STUDENT_RANK}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ fontWeight: "600", textAlign: "center" }}
                      colSpan={2}
                    >
                      <span
                        style={{
                          color: "green",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        Congratulations!{" "}
                        <span style={{ fontSize: "95%" }}>
                          You are selected for SRTST 2024
                        </span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Container>
        </Stack>
      )}
    </>
  );
}

export default ResultTable;
