import * as yup from "yup";

export const _initialValues = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

export const _validation = yup.object().shape({
  name: yup.string().required("Field is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Field is required"),
  phone: yup.string().required("Field is required"),
  phone: yup.string().required("Field is required"),
  message: yup.string().required("Field is required"),
});
