import { Box } from '@mui/material'
import React from 'react'
import ResultBanner from '../srtst/Result/ResultBanner'
import NavBar from '../srtst/Navbar/Navbar'
import FooterComponent from '../home/Footer/Footer'
import TestCenterTable from './TestCenterTable'
import TestCenterTableData from '../../json/TestCenterTableData'

const TestCenterPage = () => {
    return (
        <Box>
            <NavBar />
            <ResultBanner />
            <TestCenterTable data={TestCenterTableData} />
            <FooterComponent />
        </Box>
    )
}

export default TestCenterPage
