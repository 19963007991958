import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import VisionCard from "../../components/cardcomponents/VisionCard";
import VisionMissionData from "../../json/VisonMission";

const VisionPage = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "Column",
                margin: { md: "40px 30px", sm: "40px 0" },
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                    variant="h4"
                    sx={{ fontSize: "28px", color: "#008035", fontWeight: "600" }}
                >
                    Vision, Mission &nbsp;
                </Typography>
                <Typography
                    variant="h4"
                    sx={{ fontSize: "28px", color: "#EA5806", fontWeight: "600" }}
                >
                    & Motto
                </Typography>
            </Box>
            <Grid container mt={"40px"}>
                {VisionMissionData?.map((item) => (
                    <Grid
                        item
                        md={4}
                        sm={6}
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: { sm: "20px 0", xs: "20px 0" },
                        }}
                    >
                        <VisionCard data={item} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default VisionPage;
