import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Group from "../../../assets/Images/srtstresult.svg";
import resultbackground from "../../../assets/Images/resultbackgound.svg";
import { useForm } from "react-hook-form";

const ResultBanner = () => {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(123deg, #BFF1CD, #FFD8C0, #BFF1CD, #FFD8C0, #BFF1CD)",
      }}
    >
      <Grid container>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="flex-start"
          alignItems={"center"}
        >
          <Box
            paddingLeft={{ md: "80px", sm: "20px" }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "160px",
            }}
          >
            <Typography
              className="InterFont"
              sx={{
                color: "#000000",
                fontSize: "36px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: 1,
                width: "100%",
              }}
            >
              SRTST 2024 Result
            </Typography>
          </Box>
        </Grid>

        {/*<Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
          sx={{
            backgroundImage: `url("${resultbackground}")`,
          }}
        >
          <Box
            sx={{
              width: "500px",
              padding: "20px 0px",
              margin: "30px 0",
            }}
          >
            <img
              src={Group}
              alt=""
              style={{
                width: "100%",
                boxShadow: "0px 8px 0px  rgba(0, 0, 0, 0.1)",
              }}
            />
          </Box>
            </Grid>  */}
      </Grid>
    </Box>
  );
};

export default ResultBanner;
