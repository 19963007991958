import { Icon } from "@iconify/react/dist/iconify.js";
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import './index.css'

const AccordianCard = ({ data, i }) => {
    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };

    return (
        <Accordion
            key={i}
            expanded={expanded === `panel${i}`}
            onChange={handleChange(`panel${i}`)}
            sx={{
                border: expanded === `panel${i}` ? "2px solid #EA5806" : "#fff",
                boxShadow: "5px 11px 14px -4px rgba(0,0,0,0.1)",
                mb: 3,
                minHeight: "100px",
                alignContent: "center",
                '& .Mui-expanded:last-of-type': {
                    borderRadius: '30px'
                }
            }}
        >
            <AccordionSummary

                expandIcon={
                    expanded === `panel${i}` ? (
                        <IconButton
                            sx={{
                                background: "#EA5806",
                                borderRadius: "50%",
                                color: "#ffffff",
                                boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
                                ":hover": { background: "#ffffff", color: "#EA5806", },
                            }}
                        >
                            <Icon icon="ep:arrow-up-bold" />
                        </IconButton>
                    ) : (
                        <IconButton
                            sx={{
                                background: "#ffffff",
                                borderRadius: "50%",
                                boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
                                color: "#000000",
                                ":hover": { background: "#EA5806", color: "#ffffff" },
                            }}
                        >
                            <Icon icon="ep:arrow-right-bold" />
                        </IconButton>
                    )
                }
                aria-controls={`panel${i}-content`}
                id={`panel${i}-header`}
            >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box ml={1}>
                        <Typography
                            sx={{
                                fontSize: { sm: "20px", xs: "17px" },
                                fontWeight: "600",
                                fontFamily: "Poppins, sans-serif !important",
                                color: "#170F49",
                            }}
                        >
                            {data?.title}
                        </Typography>
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    "& .css-rx3wu4-MuiPaper-root-MuiAccordion-root.Mui-expanded:last-of-type": {
                        marginBottom: 0,
                        borderRadius: "20px",
                    },
                }}
            >
                <Typography
                    sx={{
                        ml: 1,
                        fontSize: { sm: "18px", xs: "16px" },
                        fontWeight: "500",
                        fontFamily: "Poppins, sans-serif !important",
                        color: "#6F6C90",
                    }}
                >
                    {data?.Desc}
                </Typography>
            </AccordionDetails>
        </Accordion>

    );
};

export default AccordianCard;

