import { Box, Button, Icon, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useState } from 'react'
import ArrowRightIcon from '@mui/icons-material/East';

const TestCenterTable = ({ data }) => {
    const [displayCount, setDisplayCount] = useState(14); // Initial number of items to display

    const handleShowMore = () => {
        // setDisplayCount(prevCount => prevCount + 14); 
        setDisplayCount(data.length);// Increase the display count by 14
    };
    return (
        <Box sx={{
            padding: { xs: "20px 10px", sm: "30px 20px", md: "50px 50px", lg: "50px 50px" },
            display: "flex", justifyContent: "center", flexDirection: "column",
            backgroundColor: "#ffffff",
        }}>
            <TableContainer component={Paper} sx={{ margin: "0" }}>
                <Table>

                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#EA5806" }}>
                            <TableCell
                                sx={{
                                    border: "2px solid grey",
                                    color: "#ffffff",
                                    fontWeight: "500",
                                    textAlign: "center",
                                    fontSize: "16px"
                                }}
                            >
                                {"S.No"}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "2px solid grey",
                                    color: "#ffffff",
                                    fontWeight: "500",
                                    textAlign: "center",
                                    fontSize: "16px"
                                }}
                            >
                                {"State"}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "2px solid grey",
                                    color: "#ffffff",
                                    fontWeight: "500",
                                    textAlign: "center",
                                    fontSize: "16px"
                                }}
                            >
                                {"City"}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "2px solid grey",
                                    color: "#ffffff",
                                    fontWeight: "500",
                                    textAlign: "center",
                                    fontSize: "16px"
                                }}
                            >
                                {"Test Center Code"}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "2px solid grey",
                                    color: "#ffffff",
                                    fontWeight: "500",
                                    textAlign: "center",
                                    fontSize: "16px"
                                }}
                            >
                                {"Test Date"}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "2px solid grey",
                                    color: "#ffffff",
                                    fontWeight: "500",
                                    textAlign: "center",
                                    fontSize: "16px"
                                }}
                            >
                                {"Test Zone"}
                            </TableCell>

                        </TableRow>
                    </TableHead>

                    <TableBody sx={{ borderCollapse: "collapse" }}>
                        {data?.slice(0, displayCount).map((item, index) => (
                            <React.Fragment key={item.id}>
                                <TableRow
                                    sx={{
                                        width: "100%",
                                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#ffffff",
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            border: "2px solid #c1c1c1",
                                            color: "#000000",
                                            fontWeight: "500",
                                            width: "5%",
                                            textAlign: "center",
                                            verticalAlign: "top",
                                            borderBottom: "none"
                                        }}
                                    >
                                        {index + 1} {/* Serial number */}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "2px solid #c1c1c1",
                                            color: "#000000",
                                            fontWeight: "400",
                                            width: "20%",
                                            textAlign: "center",
                                            verticalAlign: "top",
                                            borderBottom: "none"
                                        }}
                                    >
                                        {item.state}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "2px solid #c1c1c1",
                                            color: "#000000",
                                            width: "15%",
                                            fontWeight: "400",
                                            textAlign: "center",
                                            verticalAlign: "top",
                                            borderBottom: "none"
                                        }}
                                    >
                                        {item.city}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "2px solid #c1c1c1",
                                            color: "#000000",
                                            fontWeight: "400",
                                            width: "15%",
                                            textAlign: "center",
                                            verticalAlign: "top",
                                            borderBottom: "none"
                                        }}
                                    >
                                        {item.centercode}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "2px solid #c1c1c1",
                                            color: "#000000",
                                            width: "30%",
                                            fontWeight: "400",
                                            textAlign: "center",
                                            verticalAlign: "top",
                                            borderBottom: "none"
                                        }}
                                    >
                                        {item.testdate}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "2px solid #c1c1c1",
                                            color: "#000000",
                                            fontWeight: "400",
                                            textAlign: "center",
                                            verticalAlign: "top",
                                            borderBottom: "none"
                                        }}
                                    >
                                        {item.testzone}
                                    </TableCell>


                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length > displayCount && (
                <Box display={'flex'} justifyContent={'center'}>

                    <Button variant="contained" sx={{
                        backgroundColor: "#008035", margin: "20px 0", height: "40px", width: "150px", '&:hover': {
                            backgroundColor: "#008035",
                        }
                    }} onClick={handleShowMore}>
                        Show all &nbsp; <ArrowRightIcon />
                    </Button>
                </Box>
            )}
        </Box>
    )
}

export default TestCenterTable;
