import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import ImapctCard from '../../components/cardcomponents/ImapctCard'
import OurImpactData from '../../json/OurImapctData'
import line from '../../assets/Icons/imapctLine.svg'

const OurImpact = () => {
    return (
        <Box sx={{
            backgroundColor: "#BFF1CD", display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "Column",
            margin: "10px 0px",
            padding: "30px 0"
        }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                    variant="h4"
                    sx={{ fontSize: "28px", color: "#000000", fontWeight: "600" }}
                >
                    Our Impact
                </Typography>

            </Box>
            <Box container>
                <Grid container mt={"40px"} spacing={4.5}>
                    {/* <Grid sx={{display:"flex",justifyContent:"center",margin:"auto",flexDirection:{xl:"row",lg:"row",xs:"column"},gap:{xl:"35px",lg:"10px"}}}>           */}
                    {OurImpactData?.map((item) => (
                        <Grid
                            item
                            md={3}
                            sm={6}
                            xs={12}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: { sm: "10px 0", xs: "10px 0" },
                            }}
                        >
                            <ImapctCard data={item} />
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* </Grid> */}
            <Box sx={{ padding: { md: "10px", sm: "20px", xs: "0px" } }}>

                <img src={line} alt='' width="100%" />
            </Box>
        </Box>

    )
}

export default OurImpact
