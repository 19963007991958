import { Box, Button, Card, CardContent, Container, Grid } from "@mui/material";
// import {createTheme, useMediaQuery } from "@mui/material"
import { useRef, useState } from "react";
import HomeSlider1 from "../../assets/Images/homeSlider1.jpg";
import HomeSlider2 from "../../assets/Images/homeSlider2.jpg";
import HomeSlider3 from "../../assets/Images/homeSlider3.jpg";
import HomeSlider4 from "../../assets/Images/homeSlider4.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Typography from "@mui/material/Typography";
import Slider from "react-slick";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./styles.css";

function HomeBanner() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const startJourney = [
    {
      id: 1,
      title: "Lorem ipsum is placeholder text commonly used in the graphic",
      subtitle:
        "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
      image: HomeSlider1,
    },
    {
      id: 2,
      title: "Lorem ipsum is placeholder text commonly used in the graphic",
      subtitle:
        "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
      image: HomeSlider2,
    },
    {
      id: 3,
      title: "Lorem ipsum is placeholder text commonly used in the graphic",
      subtitle:
        "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
      image: HomeSlider3,
    },
    {
      id: 4,
      title: "Lorem ipsum is placeholder text commonly used in the graphic",
      subtitle:
        "Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
      image: HomeSlider4,
    },
  ];

  const carouselRef = useRef(null);
  const CustomDot = ({ ...rest }) => {
    const { active, index } = rest;
    return (
      <div
        onClick={() => carouselRef.current.goToSlide(index)}
        style={{
          border: active ? "3.5px solid #03999E" : "3.5px solid #8888",
          margin: 2,
          width: active ? "26px" : 0,
          borderRadius: 20,
          marginBottom: 16,
        }}
      />
    );
  };

  var settings = {
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    customPaging: function (i) {
      return (
        <div>
          {i === currentSlide ? (
            <svg
              width="32"
              height="14"
              viewBox="0 0 32 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="14" rx="6" fill="#ED1C24" />
            </svg>
          ) : (
            <svg
              width="15"
              height="15"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="6" cy="6" r="6" fill="#38373a" />
            </svg>
          )}
        </div>
      );
    },

    dotsClass: "slick-dots slick-thumb",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box className="home-banner">
      <Slider {...settings}>
        {startJourney?.map((item, In) => (
          <Box
            key={In + "topSlide"}
            sx={{
              height: { xs: "330px", sm: "auto", md: "600px" },
              // mt: 3,
              display: "flex",
              pr: 2,
              justifyContent: "space-between",
              borderRadius: "6px",
              position: "relative",
            }}
          >
            <Card
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "16px",
                alignItems: "center",
              }}
            >
              <LazyLoadImage
                alt="Slider image"
                effect="blur"
                src={item?.image}
                width="100%"
                height="100%"
                borderRadius="16px"
                objectFit="cover"
              />
            </Card>

            {/* <div
              style={{
                position: "absolute",
                zIndex: "20",
                top: "40px",
                left: "40px",
                width: "40%",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: "46px",
                  fontWeight: "600",
                  lineHeight: "65px",
                }}
              >
                {item?.title}
              </div>
              <div
                style={{
                  color: "white",
                  fontSize: "16px",
                  lineHeight: "27px",
                  fontWeight: "500",
                  marginTop: "30px",
                  marginBottom: "20px",
                }}
              >
                {item?.subtitle}
              </div>
              <Button
              variant="contained"
              sx={{
                backgroundColor: "#ED1C24",
                fontSize: "16px",
                lineHeight: "16px",
                padding: "15px 18px 15px 18px",
                textTransform: "none",
                marginTop: "10px",
                ":hover": {
                  backgroundColor: "#9747FF",
                },
              }}
            >
              Enroll for free
            </Button> 
            </div>*/}
          </Box>
        ))}
      </Slider>
      <Card elevation={0}>
        <CardContent
          sx={{
            margin: "10px",
          }}
        >
          <Typography
            className="InterFont"
            variant="h5"
            component="h2"
            align="center"
            style={{ fontWeight: "bold", fontSize: "40" }}
            sx={{ margin: "30px" }}
          >
            <span style={{ color: "#008035" }}>SATYA PATH</span>{" "}
            <span style={{ color: "#EA5806" }}>FOUNDATION</span>
          </Typography>
          <Typography
            className="InterFont"
            color="textSecondary"
            align="center"
            style={{
              fontFamily: "Inner",
              fontSize: "20",
              color: "black",
            }}
            sx={{ margin: "30px" }}
          >
            Established with a vision of philanthropy and human welfare, Satya
            Path Foundation is committed to addressing pressing issues such as
            livelihood, health, education, and empowerment. At Satya Path
            Foundation, we believe in a holistic approach to social development,
            ensuring that our interventions are comprehensive and sustainable.
            By working closely with marginalized communities, we strive to
            create lasting impact and uplift the lives of those in need.
          </Typography>
          <div align="center">
            <Button color="primary" href="#">
              <Typography
                color="#008035"
                className="InterFont"
                style={{ fontSize: "25", fontWeight: "normal" }}
              >
                Read more
              </Typography>
              <ArrowForwardIcon
                sx={{ color: "#008035", fontSize: 15, marginLeft: "5px" }}
              />
            </Button>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
}

export default HomeBanner;
