import { Box, Typography } from "@mui/material";
import React from "react";

const ImapctCard = ({ data }) => {
  return (
    <Box
      sx={{
        height: "200px",
        width: {xl:"300px",lg:"280px",md:"235px",sm:"250px",xs:"295px"},
        display: "flex",
        alignItems: "center",
        flexDirection:{xl:"row",lg:"row",sm:"row",xs:"column"},
        justifyContent: "center",
        backgroundColor: "#EA5806",
        borderRadius: "8px",
        boxShadow: "0 5px 5px  rgba(0, 0, 0, 0.5)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h4"
            sx={{ fontSize: "24px", color: "#ffffff", fontWeight: "600" }}
          >
            {data?.value} &nbsp;
          </Typography>
          <Typography
            variant="h4"
            sx={{ fontSize: "18px", color: "#ffffff", fontWeight: "600" }}
          >
            {data?.title}
          </Typography>
        </Box>
        <Box sx={{ padding: "5px 20px" }}>
          <Typography
            sx={{
              fontSize: "13px",
              color: "#ffffff",
              fontWeight: "300",
              textAlign: "center",
            }}
          >
            {data?.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ImapctCard;
