import gallery1 from "../assets/Images/gallery/gallery1.jpg";
import gallery2 from "../assets/Images/gallery/gallery2.jpg";
import gallery3 from "../assets/Images/gallery/gallery3.jpg";
import gallery47 from "../assets/Images/gallery/gallery47.jpg";
import gallery49 from "../assets/Images/gallery/gallery49.jpg";
import gallery50 from "../assets/Images/gallery/gallery50.jpg";
import gallery51 from "../assets/Images/gallery/gallery51.jpg";
import gallery52 from "../assets/Images/gallery/gallery52.jpg";
import gallery53 from "../assets/Images/gallery/gallery53.jpg";
import gallery54 from "../assets/Images/gallery/gallery54.jpg";
import gallery56 from "../assets/Images/gallery/gallery56.jpg";
import gallery57 from "../assets/Images/gallery/gallery57.jpg";
import gallery58 from "../assets/Images/gallery/gallery58.jpg";
import gallery60 from "../assets/Images/gallery/gallery60.jpg";
import gallery61 from "../assets/Images/gallery/gallery61.jpg";
import gallery62 from "../assets/Images/gallery/gallery62.jpg";
import gallery64 from "../assets/Images/gallery/gallery64.jpg";
import gallery66 from "../assets/Images/gallery/gallery66.jpg";
import gallery68 from "../assets/Images/gallery/gallery68.jpg";
import gallery69 from "../assets/Images/gallery/gallery69.jpg";
import gallery70 from "../assets/Images/gallery/gallery70.jpg";
import gallery71 from "../assets/Images/gallery/gallery71.jpg";
import gallery72 from "../assets/Images/gallery/gallery72.jpg";
import gallery73 from "../assets/Images/gallery/gallery73.jpg";
import gallery74 from "../assets/Images/gallery/gallery74.jpg";
import gallery75 from "../assets/Images/gallery/gallery75.jpg";
import gallery76 from "../assets/Images/gallery/gallery76.jpg";
import gallery77 from "../assets/Images/gallery/gallery77.jpg";
import gallery78 from "../assets/Images/gallery/gallery78.jpg";
import gallery79 from "../assets/Images/gallery/gallery79.jpg";
import gallery81 from "../assets/Images/gallery/gallery81.jpg";
import gallery82 from "../assets/Images/gallery/gallery82.jpg";
import gallery83 from "../assets/Images/gallery/gallery83.jpg";
import gallery85 from "../assets/Images/gallery/gallery85.jpg";
import gallery86 from "../assets/Images/gallery/gallery86.jpg";
import gallery87 from "../assets/Images/gallery/gallery87.jpg";
import gallery88 from "../assets/Images/gallery/gallery88.jpg";
import gallery89 from "../assets/Images/gallery/gallery89.jpg";
import gallery90 from "../assets/Images/gallery/gallery90.jpg";
import gallery91 from "../assets/Images/gallery/gallery91.jpg";
import gallery93 from "../assets/Images/gallery/gallery93.jpg";
import gallery97 from "../assets/Images/gallery/gallery97.jpg";
import gallery100 from "../assets/Images/gallery/gallery100.jpg";
import gallery103 from "../assets/Images/gallery/gallery103.jpg";
import gallery104 from "../assets/Images/gallery/gallery104.jpg";
import gallery105 from "../assets/Images/gallery/gallery105.jpg";
import gallery108 from "../assets/Images/gallery/gallery108.jpg";

export const photos = [
  {
    src: gallery47,
    width: 3,
    height: 2,
  },
  {
    src: gallery49,
    width: 3,
    height: 2,
  },
  {
    src: gallery50,
    width: 3,
    height: 2,
  },
  {
    src: gallery51,
    width: 3,
    height: 2,
  },
  {
    src: gallery52,
    width: 3,
    height: 2,
  },
  {
    src: gallery53,
    width: 3,
    height: 2,
  },
  {
    src: gallery54,
    width: 3,
    height: 2,
  },
  {
    src: gallery56,
    width: 3,
    height: 2,
  },
  {
    src: gallery57,
    width: 3,
    height: 2,
  },
  {
    src: gallery58,
    width: 3,
    height: 2,
  },
  {
    src: gallery60,
    width: 3,
    height: 2,
  },
  {
    src: gallery61,
    width: 3,
    height: 2,
  },
  {
    src: gallery62,
    width: 3,
    height: 2,
  },
  {
    src: gallery64,
    width: 3,
    height: 2,
  },
  {
    src: gallery66,
    width: 3,
    height: 2,
  },
  {
    src: gallery68,
    width: 3,
    height: 2,
  },
  {
    src: gallery69,
    width: 3,
    height: 2,
  },
  {
    src: gallery70,
    width: 3,
    height: 2,
  },
  {
    src: gallery71,
    width: 3,
    height: 2,
  },
  {
    src: gallery72,
    width: 3,
    height: 2,
  },
  {
    src: gallery73,
    width: 3,
    height: 2,
  },
  {
    src: gallery74,
    width: 3,
    height: 2,
  },
  {
    src: gallery75,
    width: 3,
    height: 2,
  },
  {
    src: gallery76,
    width: 3,
    height: 2,
  },
  {
    src: gallery77,
    width: 3,
    height: 2,
  },
  {
    src: gallery78,
    width: 3,
    height: 2,
  },
  {
    src: gallery79,
    width: 3,
    height: 2,
  },
  {
    src: gallery81,
    width: 3,
    height: 2,
  },
  {
    src: gallery82,
    width: 3,
    height: 2,
  },
  {
    src: gallery83,
    width: 3,
    height: 2,
  },
  {
    src: gallery85,
    width: 3,
    height: 2,
  },
  {
    src: gallery86,
    width: 3,
    height: 2,
  },
  {
    src: gallery87,
    width: 3,
    height: 2,
  },
  {
    src: gallery88,
    width: 3,
    height: 2,
  },
  {
    src: gallery89,
    width: 3,
    height: 2,
  },
  {
    src: gallery90,
    width: 3,
    height: 2,
  },
  {
    src: gallery91,
    width: 3,
    height: 2,
  },
  {
    src: gallery93,
    width: 3,
    height: 2,
  },
  {
    src: gallery97,
    width: 3,
    height: 2,
  },
  {
    src: gallery100,
    width: 3,
    height: 2,
  },
  {
    src: gallery108,
    width: 3,
    height: 2,
  },
  {
    src: gallery103,
    width: 3,
    height: 2,
  },
  {
    src: gallery104,
    width: 3,
    height: 2,
  },
  {
    src: gallery105,
    width: 3,
    height: 2,
  },
  {
    src: gallery108,
    width: 3,
    height: 2,
  },
  {
    src: gallery1,
    width: 3,
    height: 2,
  },
  {
    src: gallery2,
    width: 3,
    height: 2,
  },
  {
    src: gallery3,
    width: 3,
    height: 2,
  },
];
