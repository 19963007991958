import { Box, Typography } from '@mui/material'
import React from 'react'

const SamplePaperList = () => {
    return (
        <Box sx={{
            padding: { xs: "20px 0", sm: "30px 0", md: "50px 0", lg: "50px 0" },
            backgroundColor: "#ffffff", display: "flex", justifyContent: "center"
        }}>
            <Box sx={{ padding: { md: "0 140px", sm: "0 60px", xs: "0 40px" } }}>
                <ul>
                    {listData.map((item, index) => (
                        <li key={index}>
                            <Typography variant='h5' >{item.title}</Typography>
                            <Typography variant='subtitle1' sx={{ mb: "40px" }}>{item.description}</Typography>
                        </li>
                    ))}
                </ul>
            </Box>
        </Box>
    )
}

export default SamplePaperList;

const listData = [
    { title: 'Lorem ipsum dolor sit amet consectetur.', description: 'Lorem ipsum dolor sit amet consectetur. Amet ante commodo elementum nibh accumsan et. Facilisi nascetur a amet vitae neque eget massa. Nunc proin orci non nibh nunc varius enim. Suspendisse id non a ut lacus eu. Massa ac praesent lacus aliquam. Vestibulum penatibus sapien laoreet ornare consequat laoreet nec cursus. Quam at tristique augue habitant in id quam risus molestie. Lorem donec consequat amet porttitor sed et. Facilisi nunc faucibus dui massa nunc id ut etiam. Morbi ornare felis lectus ultrices turpis duis diam amet. Vitae massa scelerisque dis faucibus netus velit congue lobortis proin. Eleifend mi ac egestas placerat volutpat ut arcu faucibus. Egestas id pulvinar ipsum vel viverra a congue sed pellentesque. Integer id penatibus dolor amet.' },
    { title: 'Lorem ipsum dolor sit amet consectetur.', description: 'Lorem ipsum dolor sit amet consectetur. Amet ante commodo elementum nibh accumsan et. Facilisi nascetur a amet vitae neque eget massa. Nunc proin orci non nibh nunc varius enim. Suspendisse id non a ut lacus eu. Massa ac praesent lacus aliquam. Vestibulum penatibus sapien laoreet ornare consequat laoreet nec cursus. Quam at tristique augue habitant in id quam risus molestie. Lorem donec consequat amet porttitor sed et. Facilisi nunc faucibus dui massa nunc id ut etiam. Morbi ornare felis lectus ultrices turpis duis diam amet. Vitae massa scelerisque dis faucibus netus velit congue lobortis proin. Eleifend mi ac egestas placerat volutpat ut arcu faucibus. Egestas id pulvinar ipsum vel viverra a congue sed pellentesque. Integer id penatibus dolor amet.' },
];
