import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { srtstResultAsync } from "./result.async";

const initialState = {
  resultLoader: false,
  results: {},
};

export const resultSlice = createSlice({
  name: "Result",
  initialState,

  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(srtstResultAsync.pending), (state) => {
      state.resultLoader = true;
    });
    builder.addMatcher(isAnyOf(srtstResultAsync.fulfilled), (state, action) => {
      state.resultLoader = false;
      state.results = action.payload;
    });

    builder.addMatcher(isAnyOf(srtstResultAsync.rejected), (state, action) => {
      state.resultLoader = false;
    });
  },
  reducers: {
    emptyResult: (state) => {
      state.results = { message: "Data not found", Falg: "F", data: null };
    },
  },
});

export const { emptyResult } = resultSlice.actions;

export default resultSlice.reducer;
