import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import BannerImg from "../../../assets/Images/srtst-banner.jpg";
import { useForm } from "react-hook-form";
import RegistrationDialog from "./Registration";

const Banner = () => {
  const [isRegister, setIsRegister] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  const handleRegisterSubmit = () => {
    setIsRegister(true);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Grid container>
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
          sx={{
            // background: "#f26a36",
            // border: "2px solid #000",
            height: { xs: "auto", sm: "auto", md: "550px" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <img src={BannerImg} alt="" width="100%" height="100%" />
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          position: { xs: "relative", sm: "absolute" },
          top: { xs: "10px", sm: "20px", md: "120px" },
          // right: { xs: "80px", sm: "-130px", md: "-130px" },
          right: { xs: "0px", sm: "50px", md: "40px" },
          height: "auto",
          // display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { xs: "auto", sm: "430px", md: "450px" },
          border: { xs: "1px solid #ccc", sm: "none" },
          margin: { xs: "5px 10px 0px 10px", sm: "0px" },
          paddingBottom: { xs: "10px", sm: "0px" },
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            borderRadius: "15px",
            background: "#fff",
            maxWidth: "450px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // boxShadow: "0px 2px 03px 0px ",
          }}
          className="form-style"
        >
          <Box
            sx={{
              pt: "2vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "28px", sm: "30px" },
                fontWeight: "550",
              }}
            >
              Register now for FREE
            </Typography>
          </Box>
          <Box
            sx={{
              pt: { xs: "3vh", sm: "6vh", md: "9vh" },
              pb: { md: "3vh" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
              width: { xs: "90%" },
              pl: "10px",
            }}
          >
            <TextField
              {...register("mobilenumber", { required: true })}
              sx={{
                background: "#fff",
                ".MuiOutlinedInput-notchedOutline": {
                  border: 1,
                  borderColor: "#ccc",
                },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    border: 1,
                    borderColor: "#ccc",
                  },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 1,
                    borderColor: "#ccc",
                  },
                "& input::placeholder": {
                  color: "#000",
                  fontSize: {
                    xl: "20px",
                    lg: "20px",
                    md: "20px",
                    sm: "20px",
                    xs: "15px",
                  },
                  opacity: 0.5,
                },
              }}
              variant="outlined"
              id="mobilenumber"
              name="mobilenumber"
              placeholder="Mobile Number"
            />
          </Box>
          {errors.mobilenumber && (
            <span
              style={{
                color: "red",
                display: "flex",
                justifyContent: "center",
                fontSize: "15px",
              }}
            >
              Mobile number is required
            </span>
          )}
          <Box
            sx={{
              pt: { xl: "3rem", xs: "2rem" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: { xs: 1, sm: 2, md: 4 },
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                px: "6rem",
                height: "45px",
                background: "green",
                "&:hover": {
                  border: "1px solid green",
                  color: "darkgreen",
                  background: "#fff",
                },
              }}
              // onClick={handleRegisterSubmit}
            >
              Register now
            </Button>
          </Box>
        </form>
      </Box>
      {isRegister && (
        <RegistrationDialog
          isRegister={isRegister}
          setIsRegister={setIsRegister}
        />
      )}
    </Box>
  );
};

export default Banner;
