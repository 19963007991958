import React, { useState } from "react";
import { Button, Container, Grid, InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik, useField } from "formik";
import { _initialValues, _validation } from "./utils";
// import emailjs from "@emailjs/browser";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
  const onSubmit = async (values) => {
    console.log("values....", values);
  };

  const formik = useFormik({
    initialValues: _initialValues,
    onSubmit,
    validationSchema: _validation,
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <InputLabel
              htmlFor="name"
              sx={{
                color:
                  formik.touched.name && formik.errors.name ? "#d32f2f" : "",
              }}
            >
              Name
            </InputLabel>
            <TextField
              fullWidth
              variant="standard"
              name="name"
              {...formik.getFieldProps("name")}
              onChange={formik.handleChange}
              error={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel
              htmlFor="email"
              sx={{
                color:
                  formik.touched.email && formik.errors.email ? "#d32f2f" : "",
              }}
            >
              Email
            </InputLabel>
            <TextField
              fullWidth
              variant="standard"
              name="email"
              {...formik.getFieldProps("email")}
              onChange={formik.handleChange}
              error={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel
              htmlFor="phone"
              sx={{
                color:
                  formik.touched.phone && formik.errors.phone ? "#d32f2f" : "",
              }}
            >
              Phone Number
            </InputLabel>
            <TextField
              fullWidth
              variant="standard"
              name="phone"
              {...formik.getFieldProps("phone")}
              onChange={(e) => {
                const allowedChars = /^[0-9\b]+$/;
                if (
                  allowedChars.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  if (String(e.target.value).length <= 10) {
                    formik.handleChange(e);
                  }
                }
              }}
              error={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Write Your Message"
              variant="standard"
              multiline
              rows={4}
              name="message"
              {...formik.getFieldProps("message")}
              onChange={formik.handleChange}
              error={formik.touched.message && formik.errors.message}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#008035",
                border: "1px solid #008035",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#008035",
                  border: "1px solid #008035",
                },
              }}
            >
              Send Message
            </Button>
          </Grid>
        </Grid>
      </form>
      {/* <ToastContainer /> */}
    </Container>
  );
};

export default ContactForm;
