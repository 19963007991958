import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import SamplePaperCard from '../../../components/cardcomponents/SamplePaperCard'
import flower from '../../../assets/Images/spflower.svg'
const SamplePaperCardPage = () => {
  return (
    <Box sx={{
      backgroundColor: "#BFF1CD", padding: { xs: "20px 0", sm: "30px 0", md: "50px 0", lg: "50px 0" },
      display: "flex", justifyContent: "center", position: "relative"
    }}>
      <Box sx={{ position: "absolute", top: "20px", right: { md: "130px", sm: "70px", xs: "10px" }, }}>

        <img src={flower} alt='' />
      </Box>
      <Container sx={{ zIndex: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "30px 0",
          }}
        >
          <Typography variant="h4" sx={{ fontsize: "36px", fontWeight: "700" }} >Sample Papers</Typography>

        </Box>
        <Grid container spacing={3} >
          {[1, 2, 3, 4, 5, 6].map((item, i) => (
            <Grid item xs={12} md={4} sm={6} display={"flex"} justifyContent={"center"}>
              <SamplePaperCard data={item} i={i} />
            </Grid>
          ))}
        </Grid>

      </Container>


    </Box>
  )
}

export default SamplePaperCardPage
