import React from "react";
import NavBar from "../Navbar/Navbar";
import FooterComponent from "../../home/Footer/Footer";
import ResultBanner from "./ResultBanner";
import ResultForm from "./ResultForm";
 
const ResultPage = () => {
  return (
    <>
      <NavBar />
      <ResultBanner />
      <ResultForm />
      <FooterComponent />
    </>
  );
};

export default ResultPage;
