import React from "react";
// import ResultBanner from "../srtst/Result/ResultBanner";
import GalleryBanner from "./GalleryBanner"
import Navbar from "../srtst/Navbar/Navbar";
import PhotoGalleryTab from "./Tab";
import FooterComponent from "../home/Footer/Footer";

const Photo = () => {
  return (
    <div>
      <Navbar />
      <GalleryBanner />
      <PhotoGalleryTab />
      <FooterComponent />
    </div>
  );
};
export default Photo;
