import React from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "../../index.css";
import ContactForm from "./ContactForm";

const ContactMain = () => {
  return (
    <Container
      style={{
        padding: "40px 0",
      }}
    >
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        direction="column"
      >
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            color: "#EA5806",
            fontSize: { xs: "30px", sm: "32px", md: "34px", lg: "36px" },
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            textTransform: "capitalize",
            textAlign: "center",
            mt: { xs: 5 },
          }}
        >
          Contact Us
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            color: "#717171",
            fontSize: { xs: "14px", sm: "17px", md: "18px", lg: "18px" },
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textTransform: "none",
            textAlign: "center",
            width: { xs: "100%", sm: "80%", md: "80%", lg: "50%" },
            margin: "auto",
          }}
        >
          Any question or remarks? Just write a message
        </Typography>
      </Grid>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          height: "100%",
          padding: "40px",
          width: "100%",
          background: "white",
          boxShadow: "0px 0px 60px 30px rgba(0, 0, 0, 0.03)",
          borderRadius: 10,
          display: "flex",
          marginTop: "50px",
        }}
      >
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Stack
            sx={{
              background: "#008035",
              padding: {
                xs: "20px 25px",
                sm: "20px 40px",
                md: "20px 40px",
                lg: "20px 40px",
              },
              borderRadius: "9.10px",
              display: "flex",
              justifyContent: "start",
              alignItems: "space-between",
              position: "relative",
            }}
          >
            <Box>
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  color: "#fff",
                  fontSize: {
                    xs: "22.49px",
                    sm: "23.49px",
                    md: "22.49px",
                    lg: "25.49px",
                  },
                  fontStyle: "normal",
                  fontWeight: 550,
                  lineHeight: "normal",
                  textTransform: "capitalize",
                }}
              >
                Contact Information
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  color: "#ffffff",
                  fontSize: { xs: "14px", sm: "14px", md: "14px", lg: "14px" },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  textTransform: "none",

                  // width: { xs: "100%", sm: "80%", md: "80%", lg: "50%" },
                  margin: "auto",
                }}
              >
                If you have any inquiries, please don't hesitate to contact us.
                You can reach us by phone, email, or by filling out the contact
                form
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "40px 0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  color: "white",
                  fontSize: {
                    xs: "14.57px",
                    sm: "14.57px",
                    md: "14.57px",
                    lg: "14.57px",
                  },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  padding: "10px 0",
                }}
              >
                <Stack>
                  <CallIcon />
                </Stack>
                <Typography
                  variant="body2"
                  onClick={() => window.open(`tel:${+919876567896}`)}
                  sx={{
                    paddingLeft: "30px",
                  }}
                >
                  + 91-92896 72818
                </Typography>
              </Box>
              <a href={""} style={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    color: "white",
                    fontSize: {
                      xs: "14.57px",
                      sm: "14.57px",
                      md: "14.57px",
                      lg: "14.57px",
                    },
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    padding: "10px 0",
                  }}
                >
                  <Stack>
                    <EmailIcon />
                  </Stack>
                  <Typography
                    variant="body2"
                    sx={{
                      paddingLeft: "30px",
                    }}
                  >
                    scholarship@satyapath.co.in
                  </Typography>
                </Box>
              </a>
              <Box
                sx={{
                  display: "flex",
                  color: "white",
                  fontSize: {
                    xs: "14.57px",
                    sm: "14.57px",
                    md: "14.57px",
                    lg: "14.57px",
                  },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  padding: "10px 0",
                }}
              >
                <Stack>
                  <LocationOnIcon />
                </Stack>
                <Stack>
                  <Typography
                    variant="body2"
                    sx={{
                      paddingLeft: "30px",
                    }}
                  >
                    6th Floor, Plot No 15, Tradex Tower, Sector 125 , Noida,
                    Gautam Buddha Nagar, Uttar Pradesh, 201301
                  </Typography>
                </Stack>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                color: "white",
                mb: "20px",
                mt: "auto",
              }}
            >
              <a
                href="https://www.facebook.com/satyapathfoundation"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookOutlinedIcon
                  sx={{
                    marginRight: "15px",
                    border: "1px solid white",
                    borderRadius: "50%",
                    padding: "2px",
                    fontSize: "25px",
                    color: "white",
                  }}
                />
              </a>
              {/*<a href="" target="_blank" rel="noopener noreferrer">
                <LinkedInIcon
                  sx={{
                    marginRight: "15px",
                    border: "1px solid white",
                    borderRadius: "50%",
                    padding: "2px",
                    fontSize: "25px",
                    color: "white",
                  }}
                />
                </a> */}

              <a
                href="https://www.youtube.com/@SatyapathFoundation"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTubeIcon
                  sx={{
                    marginRight: "15px",
                    border: "1px solid white",
                    borderRadius: "50%",
                    padding: "2px",
                    fontSize: "25px",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://www.instagram.com/satyapath_foundation"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon
                  sx={{
                    marginRight: "15px",
                    border: "1px solid white",
                    borderRadius: "50%",
                    padding: "2px",
                    fontSize: "25px",
                    color: "white",
                  }}
                />
              </a>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={8}>
          <ContactForm />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactMain;
