import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import BenefitCard from '../../components/cardcomponents/BenefitCard'
import SrtstBenefitData from '../../json/SrtstBenefitData'

const BenefitSrtst = () => {
    return (
        <Box
            sx={{
                padding: { xs: "20px 0", sm: "30px 0", md: "50px 0", lg: "50px 0" },
                background: "#ffffff",
            }}
        >
            <Container>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 0",
                    }}
                >
                    <Typography variant="h4" sx={{ fontsize: "36px", fontWeight: "700" }} >Benefits</Typography>

                </Box>
                <Grid container spacing={3} >
                    {SrtstBenefitData?.map((item, i) => (
                        <Grid item xs={12} md={3} sm={6} display={"flex"} justifyContent={"center"}>
                            <BenefitCard data={item} i={i} />
                        </Grid>
                    ))}
                </Grid>

            </Container>
        </Box>
    )
}

export default BenefitSrtst