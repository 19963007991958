import { Box, Grid, Typography } from "@mui/material";
import quote from "../../assets/Icons/quotes.svg";
import React from "react";
import testimonial from "../../assets/Images/testimonial01.jpg";
import Craousel from "../../components/components/Craousel";
const Testimonialpage = () => {
  return (
    <Box
      sx={{
        padding: {
          xs: "20px 20px",
          sm: "30px 20px",
          md: "50px 20px",
          lg: "50px 20px",
        },
        background: "#ffffff",
      }}
    >
      <Box
        sx={{
          backgroundImage: `linear-gradient(to right, #BFF1CD , #FFD8C0)`,
          width: "100%",
          height: "auto",
          borderRadius: "20px",
          padding: "20px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "Column",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: "15px" }}>
          <Typography
            variant="h4"
            sx={{ fontSize: "28px", color: "#000000", fontWeight: "550" }}
          >
            Testimonials
          </Typography>
        </Box>

        <Grid container spacing={4}>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            display={"flex"}
            justifyContent={{ sm: "center", xs: "center", md: "flex-end" }}
          >
            <Box
              sx={{
                height: "auto",
                width: { md: "400px", sm: "300px", xs: "300px" },
                pt: "20px",
                pl: "10px",
                pr: "10px",
                borderRadius: "10px",
              }}
            >
              <img
                src={testimonial}
                alt=""
                width="100%"
                style={{ borderRadius: "10px" }}
              />
            </Box>
          </Grid>

          <Grid item md={5} sm={12} xs={12}>
            <Box sx={{ width: "50px", paddingLeft: "40px" }}>
              <img src={quote} alt="" style={{ width: "100%" }} />
            </Box>
            <Craousel />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Testimonialpage;
