import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

import program1 from "../../../assets/Images/Group 1686552253.svg";
import OurCard from "./photocard";

import Achivments from './achivment';

// const useStyles = makeStyles((theme) => ({
//     root: {
//       flexGrow: 1,
//       padding: theme.spacing(2),
//     },
//     card: {
//       backgroundColor: '#f5f5f5', // Customize the card background color
//       padding: theme.spacing(2),
//       margin: theme.spacing(2),
//     },
//   }));

const OurProgramData = [
  {
    image: program1,
    title: "Exam Conduction Process",
    description:
      "Lorem ipsum dolor sit amet consectetur. Turpis elit auctor enim enim auctor quam. Nisl lorem arcu nisi dui.",
  },
  {
    image: program1,
    title: "Exam Conduction Process",
    description:
      "Lorem ipsum dolor sit amet consectetur. Turpis elit auctor enim enim auctor quam. Nisl lorem arcu nisi dui.",
  },
  // {
  //   image: program3,
  //   title: "Women Empowerment",
  //   description:
  //     "We empower women for full potential through skills, entrepreneurship, and advocacy.",
  // },
  // {
  //   image: program4,
  //   title: "Livelihood",
  //   description:
  //     "Our livelihood programs foster stable employment and economic prosperity for individuals and families.",
  // },
  // {
  //   image: program5,
  //   title: "Empowering Grassroots",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur. Consectetur aliquam vitae cras ac.",
  // },
  // {
  //   image: program6,
  //   title: "Disaster Response",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur. Consectetur aliquam vitae cras ac.",
  // },
];

const AboutDetails = () => {
  // const classes = useStyles();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          margin: "50px 0px",
        }}
      >
        <Grid md={9} container spacing={2} mt={"0px"}>
          <Grid item md={6}>
            <Card elevation={0}>
              <CardContent
                sx={{
                  margin: "10px",
                }}
              >
                <Typography
                  className="InterFont"
                  variant="h5"
                  component="h2"
                  // align="center"
                  sx={{
                    fontSize: { md: "50px", xs: "25px" },
                  }}
                  style={{
                    fontWeight: "bold",
                  }}
                  // sx={{ margin: "30px" }}
                >
                  <span style={{ color: "#008035" }}>SATYA PATH</span>{" "}
                  <span style={{ color: "#EA5806" }}>2024</span>
                </Typography>
                <Typography
                  className="InterFont"
                  color="textSecondary"
                  // align="center"
                  sx={{
                    fontSize: { md: "20px", xs: "15px" },
                  }}
                  style={{
                    fontFamily: "Inner",
                    // fontSize: "20",
                    color: "black",
                  }}
                  // sx={{ margin: "30px" }}
                >
                  Established with a vision of philanthropy and human welfare,
                  Satya Path Foundation is committed to addressing pressing
                  issues such as livelihood, health, education, and empowerment.
                  At Satya Path Foundation, we believe in a holistic approach to
                  social development, ensuring that our interventions are
                  comprehensive and sustainable. By working closely with
                  marginalized communities, we strive to create lasting impact
                  and uplift the lives of those in need.
                </Typography>

                {OurProgramData?.map((item) => (
                  <Grid
                    item
                    // md={6}
                    // sm={6}
                    // xs={12}
                    // sx={{
                    //     display: "flex",
                    //     justifyContent: "center",
                    //     alignItems: "center",
                    //     margin: { sm: "10px 0", xs: "10px 0" },
                    // }}
                  >
                    <OurCard data={item} />
                  </Grid>
                ))}
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={6} spacing={2}>
            <Achivments/>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AboutDetails;
