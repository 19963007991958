import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

import money from "../../../assets/Images/moenyimage.svg";
import certificate from "../../../assets/Images/cerificate.svg";
import cups from "../../../assets/Images/cup.svg";
import star from "../../../assets/Images/start.svg";

const Achivments = () => {
  return (
    <>
      <Grid container spacing={2} padding={5}>
        <Grid item xs={12} sm={6} md={6}>
          <Card
            sx={{
              backgroundColor: "white",
              display: "flex", // Add display flex to make the container flexbox
              alignItems: "center", // Center items vertically
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Box height={"200px"}>
                <Box
                  height={"50px"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={money} alt="" />
                  <Typography variant="body2" textAlign={"center"}>
                    Cash Prizes
                  </Typography>
                  <Typography
                    variant="h6"
                    textAlign={"center"}
                    fontWeight={700}
                  >
                    ₹ 2.4 cr.
                  </Typography>
                  <Typography color="textSecondary" textAlign={"center"}>
                    Online & Offline
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Card
            sx={{
              backgroundColor: "white",
              display: "flex", // Add display flex to make the container flexbox
              alignItems: "center", // Center items vertically
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Box height={"200px"}>
                <Box
                  height={"50px"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={certificate} alt="" />
                  <Typography variant="body2" textAlign={"center"}>
                    Scholarships worth
                  </Typography>
                  <Typography
                    variant="h6"
                    textAlign={"center"}
                    fontWeight={700}
                  >
                    ₹ 530 cr.
                  </Typography>
                  <Typography color="textSecondary" textAlign={"center"}>
                    Online & Offline
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Card
            sx={{
              backgroundColor: "white",
              display: "flex", // Add display flex to make the container flexbox
              alignItems: "center", // Center items vertically
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Box height={"200px"}>
                <Box
                  height={"50px"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={cups} alt="" />
                  <Typography variant="body2" textAlign={"center"}>
                    Total Prizes for Students
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6}>
                      <Box>
                        <Typography
                          variant="h6"
                          textAlign={"center"}
                          fontWeight={700}
                        >
                          2334
                        </Typography>
                        <Typography color="textSecondary" textAlign={"center"}>
                          (Online)
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <Box>
                        <Typography
                          variant="h6"
                          textAlign={"center"}
                          fontWeight={700}
                        >
                          2334
                        </Typography>
                        <Typography color="textSecondary" textAlign={"center"}>
                          (Offline)
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Card
            sx={{
              backgroundColor: "white",
              display: "flex", // Add display flex to make the container flexbox
              alignItems: "center", // Center items vertically
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Box height={"200px"}>
                <Box
                  height={"50px"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={star} alt="" />
                  <Typography variant="body2" textAlign={"center"}>
                    Scholarships For Satya Classroom and Digital Courses
                  </Typography>
                  <Typography
                    variant="h6"
                    textAlign={"center"}
                    fontWeight={700}
                  >
                    Upto 90%
                  </Typography>
                  <Typography color="textSecondary" textAlign={"center"}>
                    Online & Offline
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Achivments;
