import { Box } from '@mui/material'
import React from 'react'
import Navbar from './../Navbar/Navbar'
import Footer from './../../home/Footer/Footer'
import ResultBanner from "./../Result/ResultBanner";
import Detail from './details'


const AboutSrtst = () => {
  return (
    <>
    <Box>
        <Navbar />
        <ResultBanner />
        <Detail />
        <Footer />
    </Box>
</>
  )
}

export default AboutSrtst
