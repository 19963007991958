import React from "react";
import NavBar from "../Navbar/Navbar";
import FooterComponent from "../../home/Footer/Footer";
import ResultBanner from "../Result/ResultBanner";
import { Box, Typography } from "@mui/material";
import ButtonComponent from "../../../components/components/ButtonComponent";

const ResultPage = () => {
  return (
    <>
      <NavBar />
      <ResultBanner />
      <Box
        sx={{
          paddingTop: { xs: "20px ", sm: "30px ", md: "50px ", lg: "50px " },
          padding: { md: "0 80px", sm: "0 20px" },
          backgroundColor: "#ffffff",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        <Box
          padding={"0  30px"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <Typography
            className="InterFont"
            sx={{
              color: "#000000",
              fontSize: "30px",

              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: 1,
              textAlign: "center",
              paddingTop: {
                xs: "1px",
                sm: "2px",
                md: "15px",
                lg: "20px"
              },
              paddingBottom: {
                xs: "1px",
                sm: "2px",
                md: "15px",
                lg: "20px"
              },

              width: "100%"
            }}
          >
            Lorem ipsum dolor sit amet consectetur.
          </Typography>

          <Typography
            varient="body1"
            className="InterFont"
            sx={{
              color: "#1C1B1B",
              fontSize: "18px",
              fontWeight: 400,
              fontFamily: "Inter",
              textAlign: "center",

              lineHeight: 1,
              width: "100%"
            }}
          >
            Lorem ipsum dolor sit amet consectetur. Posuere aenean elementum
            lacinia et est duis congue vitae aliquet. In dignissim praesent auctor
            faucibus nulla turpis integer egestas. Purus morbi sit eget neque
            placerat.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", margin: "30px 0" }}>
          <ButtonComponent
            borderRadius="5px"
            text="Download Admit Card"
            fontSize="18px"
            width={{ md: "600px", sm: "400px", xs: "300px" }}
            height="50px"
          />
        </Box>
      </Box>
      <FooterComponent />
    </>
  );
};

export default ResultPage;
