import React, { useState } from "react";
import {

    Box,
    Container,
    Grid,
    Typography,

} from "@mui/material";

import ButtonComponent from "../../components/components/ButtonComponent";
import AccordianCard from "../../components/components/AccordianCard"

const initialDisplayCount = 5;


const SrtstFaq = ({ faqData }) => {
    const [displayCount, setDisplayCount] = useState(initialDisplayCount);

    const handleExploreAllClick = () => {
        setDisplayCount(faqData.length);
    };

    const handleShowLessClick = () => {
        setDisplayCount(initialDisplayCount);
    };
    return (
        <Box
            sx={{
                padding: { xs: "20px 0", sm: "30px 0", md: "50px 0", lg: "50px 0" },
                background: "#F6F6F6",
            }}
        >
            <Container>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "30px 0",
                    }}
                >
                    <Typography variant="h4" sx={{ fontsize: "36px", fontWeight: "700" }} >FAQ’s</Typography>

                </Box>
                <Grid container spacing={3}>
                    {faqData?.slice(0, displayCount).map((item, i) => (
                        <Grid item xs={12} md={12}>


                            <AccordianCard data={item} i={i} />

                        </Grid>
                    ))}
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                        marginBottom: "25px",
                    }}
                >

                    <ButtonComponent
                        borderRadius="10px"
                        text={displayCount === initialDisplayCount ? "Explore All" : "Show Less"}
                        fontSize="18px"
                        padding="10px 10px"
                        width="200px"
                        height="50px"
                        onClick={displayCount === initialDisplayCount ? handleExploreAllClick : handleShowLessClick}
                    />

                </Box>
            </Container>
        </Box>
    );
};

export default SrtstFaq;

