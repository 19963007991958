const SrtstFaqData = [
  {
    title: "Who can appear in SRTST 2025?",
    Desc: "All students of class 8 transitioning to class 9 can appear for the exam.",
  },
  {
    title: "How and when will the exam be conducted?",
    Desc: "SRTST exam will be conducted in offline mode. It be held on 7th April 2024.",
  },
  {
    title: "What is the Syllabus for SRTST?",
    Desc: "Go to the top of the page and register yourself, our team will contact you shortly.",
  },
  {
    title: "When will results get announced?",
    Desc: "The results will be announced on 1st May 2024.",
  },
  {
    title: "What is the fee for SRTST 2024?",
    Desc: "There is no registration fee for the exam. ",
  },
  {
    title: "How to Apply for SRTST 2024?",
    Desc: "Go to the top of the page and register yourself, our team will contact you shortly.",
  },
  {
    title: "What is the last day of registration?",
    Desc: "The last day of registration is 1st April 2024.",
  },
  {
    title: "What should a student carry with them to the exam hall?",
    Desc: "You will need a Black or Blue ballpoint pen to fill out your OMR sheet, and an identity card",
  },
];
export default SrtstFaqData;
