import vision from "../assets/Icons/vision.svg";
import mision from "../assets/Icons/Mission.svg";
import motto from "../assets/Icons/Motto.svg";
const VisionMissionData = [
  {
    image: vision,
    title: "Vision",
    description:
      "To empower and sustainably develop a harmonious society by offering assistance in health, education, empowerment and other essential facilities to those in need.",
  },
  {
    image: mision,
    title: "Mission",
    description:
      "Our mission is to promote cultural well-being, foster health, and enhance livelihoods by providing comprehensive support in education, healthcare, empowerment initiatives, and essential services, thus empowering individuals and communities to thrive.",
  },
  {
    image: motto,
    title: "Motto",
    description: "Education, Well Being and Health for All.",
  },
];
export default VisionMissionData;
