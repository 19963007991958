import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Container, Typography } from "@mui/material";
import Gallery from "./PhotoGallery";

export default function PhotoGalleryTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [selectedTab, setSelectedTab] = React.useState(0);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ mb: "20px" }}>
      <Container>
        <Box textAlign="center">
          {/*<Box>
            <Typography
              className="InterFont"
              sx={{ color: "#EA5806", fontSize: "25px", mt: 3 }}
            >
              Gallery
            </Typography>
        </Box> */}
          <Box>
            <Typography
              className="InterFont"
              sx={{ fontSize: "25px", fontWeight: "600", mb: 3, mt: 3 }}
            >
              Photo Gallery
            </Typography>
          </Box>
        </Box>

        <Box sx={{ width: "100%" }}>
          {/*<Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="scrollable auto tabs example"

                        sx={{
                            fontSize: "13px",
                            padding: { md: "0 140px", sm: "0 10px" },
                            // mt: "40px",
                            "& .MuiTab-root": {
                                // fontSize: "13px",
                                color: "#000",
                                backgroundColor: "#FFD8C0",

                                "&.Mui-selected": {
                                    color: "#fff",
                                    backgroundColor: "#EF5835",
                                },

                            },
                            "& .MuiTabs-indicator": {
                                display: "none"
                            },
                            "& .MuiTabs-flexContainer": {
                                display: "flex",
                                justifyContent: "flex-start",
                                mt: "2%",
                                mb: "2%",
                                gap: "2%",
                            }, "& .MuiTabScrollButton-root": {
                                color: "#ffffff" // Change color of scroll buttons to white
                            }
                        }}
                    >
                        <Tab label="All" />
                        <Tab label="Success Power Session" />
                        <Tab label="Exam Glimpses" />

                    </Tabs> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            {selectedTab === 0 && <Gallery />}
            {selectedTab === 1 && <Gallery />}
            {selectedTab === 2 && <Gallery />}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
