import { Box } from '@mui/material'
import React from 'react'
import NavBar from '../Navbar/Navbar'
import ResultBanner from '../Result/ResultBanner'
import FooterComponent from '../../home/Footer/Footer'
import SamplePaperList from './SamplePaperList'
import SamplePaperCardPage from './SamplePaperCardPage'

const SamplePaper = () => {
    return (
        <>
            <NavBar />
            <ResultBanner />
            <SamplePaperList />
            <SamplePaperCardPage />
            <FooterComponent />
        </>
    )
}

export default SamplePaper
