import React from "react";
import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/HomePage";
import SrtstPage from "../pages/srtst/SrtstPage";
import ResultPage from "../pages/srtst/Result/ResultPage";
import SamplePaper from "../pages/srtst/SamplPaper/SamplePaper";
import ContactUsPage from "../pages/Contact/ContactUsPage";
import ContactUsPageHome from "../pages/Contact/ContactUsPageHome";
import AboutHome from "../pages/home/About/about"
import AboutSrtst from "../pages/srtst/About/about";
import AdmitCard from "../pages/srtst/AdmitCard/AdmitCard"
import Photo from "../pages/Gallery/Photo";
import Video from "../pages/Gallery/Video";
import TestCenterPage from "../pages/TestCenter/TestCenterPage";
// import HomePage from "../pages/home/HomePage";

const appRouter = createBrowserRouter([
  { path: "/", element: <HomePage /> },
  { path: "/contactus", element: <ContactUsPageHome /> },
  { path: "/about", element: <AboutHome /> },
  { path: "srtst", element: <SrtstPage /> },
  { path: "srtst/result", element: <ResultPage /> },
  { path: "srtst/samplepaper", element: <SamplePaper /> },
  { path: "srtst/contactus", element: <ContactUsPage /> },
  { path: "srtst/gallery/photos", element: <Photo /> },
  { path: "srtst/gallery/videos", element: <Video /> },
  { path: "srtst/about", element: <AboutSrtst /> },
  { path: "srtst/admitcard", element: <AdmitCard /> },
  { path: "srtst/TestCenter", element: <TestCenterPage /> },
]);

export default appRouter;
