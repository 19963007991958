import benefiticon from "../assets/Icons/benfitIcon.svg";

const SrtstBenefitData = [
  {
    image: benefiticon,
    title: "Cash Prize",
    Desc: "Earn a cash prize for your outstanding performance in our scholarship test, rewarding your academic excellence and dedication to learning.",
  },
  {
    image: benefiticon,
    title: "1-1 Mentorship",
    Desc: "Receive personalised guidance and support from experienced mentors to help you excel academically and achieve your educational goals.",
  },
  {
    image: benefiticon,
    title: "Learning Kit",
    Desc: "Get equipped with a comprehensive learning kit filled with essential resources and tools to enhance your study experience and broaden your knowledge.",
  },
  {
    image: benefiticon,
    title: "Skill-building Workshops",
    Desc: "Get access to skill-building workshops designed to develop specific abilities like communication, problem-solving, and creativity",
  },
];
export default SrtstBenefitData;
