import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha, useMediaQuery, useTheme, Drawer } from "@mui/material";
import LOGO from "../../../assets/Icons/appbarimage.svg"; // Adjust the path to your image asset
import Select from "@mui/material/Select"; // Import Select component
import { useState } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

function ResponsiveAppBar() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [hideLogo, setHideLogo] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  // const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const [condition, setCondition] = useState(false);
  const [defaultValueValue, setDefaultValueValue] = useState(10);

  // Define the 'settings' array
  // const settings = ["Setting 1", "Setting 2", "Setting 3"];

  return (
    <AppBar sx={{ background: "#fff", width: "100%", postion: "relative" }}>
      <Box maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: { xl: "1px", lg: "4px", md: "0rem", sm: "0%", xs: "6rem" },
            padding: { xl: "0 55px", lg: "0 55px" },
          }}
        >
          <Box
            sx={{
              maxWidth: "100%",
              overflow: "hidden",
              width: { md: "200px", sm: "100px", xs: "180px" },
            }}
          >
            <NavLink
              to={"/"}
              style={{ textDecoration: "none", color: "#000000" }}
            >
              <img
                src={LOGO}
                alt="logo img"
                style={{ width: "100%", height: "auto" }}
              />
            </NavLink>
          </Box>

          <Box
            sx={{
              justifyContent: "center",
              flexGrow: 1,
              display: {
                xl: "screen",
                lg: "screen",
                md: "screen",
                sm: "screen",
                xs: "none",
              },
              mx: 3,
              fontSize: "1px",
            }}
          >
            <Typography>
              <Button
                sx={{
                  color: "black",
                  fontSize: { xl: "1rem", lg: "1rem", md: "10px", sm: "10px" },
                  marginTop: { xl: "", lg: "14px", md: "13px" },
                  textAlign: "center",
                  marginLeft: { xl: "0%", lg: "0%", md: "0%", sm: "-25%" },
                }}
              >
                <Link
                  to={"/"}
                  style={{ textDecoration: "none", color: "#000000" }}
                >
                  Home
                </Link>
              </Button>
            </Typography>
            <Typography>
              <Button
                sx={{
                  color: "black",
                  fontSize: { xl: "1rem", lg: "1rem", md: "10px", sm: "10px" },
                  marginTop: { xl: "", lg: "14px", md: "13px" },
                  textAlign: "center",
                  marginLeft: { xl: "0%", lg: "0%", md: "0%", sm: "-25%" },
                }}
              >
                |
              </Button>
            </Typography>
            <Typography>
              <Button
                sx={{
                  color: "black",
                  fontSize: { xl: "1rem", lg: "1rem", md: "10px", sm: "10px" },
                  marginTop: { xl: "", lg: "14px", md: "13px" },
                  textAlign: "center",
                  marginLeft: { xl: "0%", lg: "0%", md: "0%", sm: "-25%" },
                }}
              >
                <Link
                  to={"/srtst"}
                  style={{ textDecoration: "none", color: "#000000" }}
                >
                  SRTST{" "}
                </Link>
              </Button>
            </Typography>
            <Typography>
              <Button
                sx={{
                  color: "black",
                  fontSize: { xl: "1rem", lg: "1rem", md: "10px", sm: "10px" },
                  marginTop: { xl: "", lg: "14px", md: "13px" },
                  textAlign: "center",
                  marginLeft: { xl: "0%", lg: "0%", md: "0%", sm: "-25%" },
                }}
              >
                |
              </Button>
            </Typography>
            <Typography>
              <Button
                sx={{
                  color: "black",
                  fontSize: { xl: "1rem", lg: "1rem", md: "10px", sm: "10px" },
                  marginTop: { xl: "", lg: "14px", md: "13px" },
                  textAlign: "center",
                  marginLeft: { xl: "0%", lg: "0%", md: "0%", sm: "-25%" },
                }}
              >
                {/*<Link
                  to={"/about"}
                  style={{ textDecoration: "none", color: "#000000" }}
                >
                  About Us
              </Link> */}
                About Us
              </Button>
            </Typography>
            <Typography>
              <Button
                sx={{
                  color: "black",
                  fontSize: { xl: "1rem", lg: "1rem", md: "10px", sm: "10px" },
                  marginTop: { xl: "", lg: "14px", md: "13px" },
                  textAlign: "center",
                  marginLeft: { xl: "0%", lg: "0%", md: "0%", sm: "-25%" },
                }}
              >
                |
              </Button>
            </Typography>
            <Typography>
              <Button
                sx={{
                  color: "black",
                  fontSize: { xl: "1rem", lg: "1rem", md: "10px", sm: "10px" },
                  marginTop: { xl: "", lg: "14px", md: "13px" },
                  textAlign: "center",
                  marginLeft: { xl: "0%", lg: "0%", md: "0%", sm: "-25%" },
                }}
              >
                <Link
                  to={"/contactus"}
                  style={{ textDecoration: "none", color: "#000000" }}
                >
                  Contact
                </Link>
              </Button>
            </Typography>
          </Box>

          <Box
            sx={{
              flexGrow: 0,
              display: {
                xl: "screen",
                lg: "screen",
                md: "screen",
                sm: "screen",
                xs: "none",
              },
            }}
          >
            <Button
              variant="container"
              sx={{
                background: "green",
                fontSize: { md: "1rem", sm: "0.6rem" },
                "&:hover": {
                  border: "1px solid green",
                  color: "green",
                  background: "#fff",
                },
              }}
            >
              Call Now
            </Button>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))} */}
            </Menu>
          </Box>

          <Box sx={{ display: { xs: "block", md: "none", sm: "none" } }}>
            <IconButton
              size="large"
              aria-label="open navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              // onClick={handleOpenNavMenu}
              onClick={handleDrawerOpen}
              color="black"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerClose}
                ModalProps={{ keepMounted: true }}
                sx={{
                  "& .MuiPaper-root": {
                    // marginLeft:"3px",
                    // alignItems:"center",
                    // display:"flex",
                    // justifyContent:"center"
                  },
                }}
              >
                <Box
                  sx={{
                    maxWidth: "100%",
                    overflow: "hidden",
                    width: { md: "200px", sm: "100px", xs: "180px" },
                    ml: "30px",
                    mt: "10px",
                  }}
                >
                  <NavLink
                    to={"/"}
                    style={{ textDecoration: "none", color: "#000000" }}
                  >
                    <img
                      src={LOGO}
                      alt="logo img"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </NavLink>
                </Box>

                <Box
                  mt={2}
                  sx={{
                    gap: "2rem",
                    flexGrow: 1,
                    display: { xl: "none", lg: "none", md: "none" },
                    width: 250,
                  }}
                >
                  <Typography>
                    <Button
                      sx={{
                        color: "black",
                        pl: "35%",
                        // fontWeight:"600",

                        fontSize: {
                          xl: "1rem",
                          lg: "1rem",
                          md: "10px",
                          sm: "10px",
                        },
                        marginTop: { xl: "", lg: "14px", md: "13px" },
                        textAlign: "center",
                        marginLeft: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "-25%",
                        },
                      }}
                    >
                      <Link
                        to={"/"}
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        Home
                      </Link>
                    </Button>
                  </Typography>

                  <Typography>
                    <Button
                      sx={{
                        color: "black",
                        pl: "35%",
                        // fontWeight:"600",

                        fontSize: {
                          xl: "1rem",
                          lg: "1rem",
                          md: "10px",
                          sm: "10px",
                        },
                        marginTop: { xl: "", lg: "14px", md: "13px" },
                        textAlign: "center",
                        marginLeft: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "-25%",
                        },
                      }}
                    >
                      <Link
                        to={"/srtst"}
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        SRTST{" "}
                      </Link>
                    </Button>
                  </Typography>

                  <Typography>
                    <Button
                      sx={{
                        color: "black",
                        pl: "35%",
                        // fontWeight:"600",

                        fontSize: {
                          xl: "1rem",
                          lg: "1rem",
                          md: "10px",
                          sm: "10px",
                        },
                        marginTop: { xl: "", lg: "14px", md: "13px" },
                        textAlign: "center",
                        marginLeft: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "-25%",
                        },
                      }}
                    >
                      {/*<Link
                        to={"/about"}
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        About Us
                    </Link> */}
                      About Us
                    </Button>
                  </Typography>
                  <Typography>
                    <Button
                      sx={{
                        color: "black",
                        pl: "35%",
                        // fontWeight:"600",

                        fontSize: {
                          xl: "1rem",
                          lg: "1rem",
                          md: "10px",
                          sm: "10px",
                        },
                        marginTop: { xl: "", lg: "14px", md: "13px" },
                        textAlign: "center",
                        marginLeft: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "-25%",
                        },
                      }}
                    >
                      <Link
                        to={"/contactus"}
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        Contact
                      </Link>
                    </Button>
                  </Typography>
                </Box>
                <Box mt={5}>
                  <Button
                    variant="container"
                    sx={{
                      background: "green",
                      display: "flex",
                      mb: "10px",
                      ml: "30%",
                      color: "#fff",
                    }}
                  >
                    Call Now
                  </Button>
                </Box>
              </Drawer>
            </Menu>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}

export default ResponsiveAppBar;
