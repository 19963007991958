import video1 from '../assets/wb.mp4'
const VideoGalleryData = [
    {
      video: video1,
    },
    {
      video: video1,
    },
    {
      video: video1,
    },
    {
      video: video1,
    },
    {
      video: video1,
    },
    {
      video: video1,
    },
    {
      video: video1,
    },
    {
      video: video1,
    },
    {
      video: video1,
    },
   
   
    
    // Add more videos as needed
  ];
  
export default VideoGalleryData;
