import { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import { CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { srtstResultAsync } from "../../../redux/srtst/result.async";
import { emptyResult } from "../../../redux/srtst/result.slice";

import ResultTable from "./ResultTable";
import resultUrl from "../../../assets/pdf/SRTST_Result _List_2024.pdf";

const ResultForm = () => {
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const { results, resultLoader } = useSelector((state) => state.result);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let payload = {
      ROLL_NUMBER: data?.rollnumber,
    };
    dispatch(srtstResultAsync(payload)).then((res) => {
      if (res?.payload?.success == true) {
      }
    });
    setIsSubmit(true);
  };

  useEffect(() => {
    dispatch(emptyResult());
    setIsSubmit(false);
  }, []);

  const downloadList = () => {
    window.open(resultUrl, "_blank");
  };

  return (
    <Box
      sx={{
        paddingTop: { xs: "20px ", sm: "30px ", md: "50px ", lg: "50px " },
        backgroundColor: "#ffffff",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          pt: { xl: "0rem", xs: "1rem" },
          pb: { xl: "0rem", xs: "5rem" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          className="flash-button"
          sx={{ fontFamily: "'Poppins', sans-serif", width: "60%" }}
          onClick={downloadList}
        >
          Download Selected Student List
        </Box>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            borderRadius: "5px",
            background: "#ffffff",
            width: "60%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.3)",
            border: "2px solid Grey",
            margin: "10px",
            padding: "30px 0px 30px 0px",
            marginBottom: "50px",
          }}
          className="form-responsive"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "90%", md: "80%" },
            }}
          >
            <TextField
              {...register("rollnumber", { required: true })}
              sx={{
                background: "#ffffff",
                width: "500px",
                "& input::placeholder": {
                  color: "#000",
                  fontSize: {
                    xl: "20px",
                    lg: "20px",
                    md: "20px",
                    sm: "20px",
                    xs: "15px",
                  },
                  opacity: 0.7,
                },
              }}
              variant="outlined"
              id="rollnumber"
              name="rollnumber"
              placeholder="Enter Roll Number"
            />
          </Box>
          {errors.rollnumber && (
            <span
              style={{
                color: "red",
                display: "flex",
                justifyContent: "center",
                fontSize: "15px",
                marginTop: "10px",
              }}
            >
              Roll Number is required
            </span>
          )}
          <Box
            sx={{
              pt: { xl: "3rem", xs: "2rem" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "90%", md: "80%" },
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: "500px",
                height: "50px",
                background: "#008035",
                textTransform: "none",
                fontSize: "18px",

                "&:hover": {
                  border: "1px solid #008035",
                  color: "#008035",
                  background: "#fff",
                },
              }}
            >
              View Result
            </Button>
          </Box>
          <Box sx={{ width: { xs: "90%", md: "80%" } }}>
            {resultLoader ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <CircularProgress />
              </Box>
            ) : results?.data !== null ? (
              <ResultTable results={results} />
            ) : isSubmit && results?.Falg == "F" ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <Typography
                  sx={{ fontWeight: "600", color: "red", fontSize: "16px" }}
                >
                  {results?.message}
                </Typography>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ResultForm;
