import { Box, Card, Typography } from "@mui/material";
import React from "react";
import bottomcurve from "../../assets/Icons/bottomcurve.svg"
import topcurve from "../../assets/Icons/topcurve.svg"

const VisionCard = ({ data }) => {
    return (
        <Box >

            <Card
                sx={{
                    borderRadius: "50%",
                    height: "300px",
                    width: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderStyle: "solid",
                    borderColor: "#EA5806",
                    borderWidth: "2px",
                    position: "relative",
                    overflow: "visible"

                }}
            >

                {/* top curve image */}
                <img
                    src={topcurve}
                    alt="Bottom Curve"
                    style={{
                        position: "absolute",
                        top: "-18px",
                        right: "-25px",

                        width: "auto", // Adjust as necessary
                        height: "auto", // Adjust as necessary
                    }}
                />
                {/* Bottom curve image */}
                <img
                    src={bottomcurve}
                    alt="Bottom Curve"
                    style={{
                        position: "absolute",
                        bottom: "-18px",
                        left: "-25px",

                        width: "auto", // Adjust as necessary
                        height: "auto", // Adjust as necessary
                    }}
                />
                <Card
                    sx={{
                        borderRadius: "50%",
                        boxShadow: "0 2px 2px 2px rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "260px",
                        width: "260px",
                        // border: "2px solid #EA5806",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "10px",
                            borderRadius: "50%",
                            // marginTop: "10px",
                        }}
                    >
                        <img src={data?.image} alt="" width={"60px"} />
                        <Typography className="InterFont"
                            sx={{ fontSize: "22px", color: "#008035", fontWeight: "700" }}
                        >
                            {data?.title}
                        </Typography>
                        <Typography
                            sx={{ fontSize: "10px", textAlign: "center", padding: "0 10px" }}
                        >
                            {data?.description}
                        </Typography>
                    </Box>
                </Card>
            </Card>

        </Box>


    );
};

export default VisionCard;
