const TestCenterTableData = [
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
    {
        state: "Haryana",
        city: "Sonipat",
        centercode: "10099",
        testdate: "Sunday, 29 October 2024",
        testzone: "H G Khorana",
    },
];

export default TestCenterTableData;