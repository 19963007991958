import program1 from "../assets/Icons/program1.png";
import program2 from "../assets/Icons/program2.png";
import program3 from "../assets/Icons/program3.png";
import program4 from "../assets/Icons/program4.png";
import program5 from "../assets/Icons/program5.png";
import program6 from "../assets/Icons/program6.png";
const OurProgramData = [
  {
    image: program1,
    title: "Education",
    description:
      "Our education initiatives ensure quality learning for all, paving the way for lifelong success and empowerment.",
  },
  {
    image: program2,
    title: "Healthcare",
    description:
      "Our healthcare programs ensure vitality and resilience through essential services and preventive care.",
  },
  {
    image: program3,
    title: "Women Empowerment",
    description:
      "We empower women for full potential through skills, entrepreneurship, and advocacy.",
  },
  {
    image: program4,
    title: "Livelihood",
    description:
      "Our livelihood programs foster stable employment and economic prosperity for individuals and families.",
  },
  // {
  //   image: program5,
  //   title: "Empowering Grassroots",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur. Consectetur aliquam vitae cras ac.",
  // },
  // {
  //   image: program6,
  //   title: "Disaster Response",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur. Consectetur aliquam vitae cras ac.",
  // },
];
export default OurProgramData;
