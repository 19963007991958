import React from "react";
import { Box, Container, Typography } from "@mui/material";
import ButtonComponent from "../../components/components/ButtonComponent";
import pdfUrl from "../../assets/pdf/Syllabus_SRTST.pdf";

const downloadHandler = () => {
  window.open(pdfUrl, "_blank");
};

const SrtstSyllabus = () => {
  return (
    <Box
      sx={{
        padding: { xs: "20px 0", sm: "30px 0", md: "50px 0", lg: "50px 0" },
        backgroundColor: "#ffffff",
      }}
    >
      <Container>
        <Box
          sx={{
            backgroundColor: "#BFF1CD",
            height: "auto",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "Column",
            padding: "20px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: "15px" }}>
            <Typography
              variant="h4"
              sx={{ fontSize: "28px", color: "#EA5806", fontWeight: "550" }}
            >
              Syllabus and&nbsp;
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontSize: "28px", color: "#008035", fontWeight: "550" }}
            >
              Pattern
            </Typography>
          </Box>
          <Typography
            variant="caption"
            sx={{
              fontSize: "16px",
              color: "#000000",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            SRTST syllabus includes the Class 8th syllabus of NCERT & State
            boards. The subjects includes Science, Mathematics, English, Hindi,
            General Knowledge and Reasoning.
          </Typography>
          <Box sx={{ margin: "10px 0" }}>
            <ButtonComponent
              borderRadius="5px"
              text="Download Now"
              fontSize="18px"
              padding="5px 5px"
              width="190px"
              height="45px"
              onClick={downloadHandler}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SrtstSyllabus;
