import { Box, Rating, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
// import testimonial from "../../assets/Icons/srtsttestimonial.svg";
import testimonial from "../../assets/Images/rank3.jpg";
import testimonial2 from "../../assets/Images/rank8.jpg";

const Craousel = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <Slider {...settings}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "380px",
          // position: "relative"
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              height: "220px",
              width: "270px",
              borderRadius: "20px",
              alignItems: "center",
              backgroundColor: "#ffffff",
              boxShadow: "0px 5px 5px rgba(0,0,0,0.3)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                marginTop: "-35px",
                width: "80px",
                height: "80px",
                backgroundColor: "#fff",
                px: 1,
                py: 1,
                borderRadius: "50%",
              }}
            >
              <img
                src={testimonial}
                alt=""
                style={{
                  aspectRatio: 1 / 1,
                  width: "98%",
                  borderRadius: "50%",
                }}
              />
            </Box>
            <Typography sx={{ mt: "10px" }}>Pratyusha Mishra</Typography>
            <Rating name="disabled" value={5} sx={{ mt: "10px" }} />
          </Box>
          <Box
            sx={{
              height: "auto",
              width: "270px",
              borderRadius: "20px 0px 20px 0px",
              alignItems: "center",
              backgroundColor: "#ffffff",
              boxShadow: "0px 5px 5px rgba(0,0,0,0.3)",
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              left: "30px",
              bottom: "40px",
              px: 2,
              py: 2,
            }}
          >
            <Typography variant="body1" fontSize={"13px"}>
              Winning the scholarship and being recognized for my achievements
              was a moment of pride for me and my family.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "380px",
          // position: "relative"
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              height: "220px",
              width: "270px",
              borderRadius: "20px",
              alignItems: "center",
              backgroundColor: "#ffffff",
              boxShadow: "0px 5px 5px rgba(0,0,0,0.3)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                marginTop: "-35px",
                width: "80px",
                height: "80px",
                backgroundColor: "#fff",
                px: 1,
                py: 1,
                borderRadius: "50%",
              }}
            >
              <img
                src={testimonial2}
                alt=""
                style={{
                  aspectRatio: 1 / 1,
                  width: "98%",
                  borderRadius: "50%",
                }}
              />
            </Box>
            <Typography sx={{ mt: "10px" }}>Prachi Singh</Typography>
            <Rating name="disabled" value={5} sx={{ mt: "10px" }} />
          </Box>
          <Box
            sx={{
              height: "auto",
              width: "270px",
              borderRadius: "20px 0px 20px 0px",
              alignItems: "center",
              backgroundColor: "#ffffff",
              boxShadow: "0px 5px 5px rgba(0,0,0,0.3)",
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              left: "30px",
              bottom: "40px",
              px: 2,
              py: 2,
            }}
          >
            <Typography variant="body1" fontSize={"13px"}>
              The scholarship test challenged me to push my limits and showcase
              my abilities. Winning the cash prize was an incredible bonus.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Slider>
  );
};

export default Craousel;
