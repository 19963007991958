import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Drawer, MenuItem } from "@mui/material";
import LOGO from "../../../assets/Icons/appbarimage.svg";
import { NavLink } from "react-router-dom";

function ResponsiveAppBar() {
  const appBarRef = useRef(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [currentTab, setCurrentTab] = React.useState("about");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  // const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
    setShowGalleryOptions(false);
    setShowDownloadOptions(false);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const NavLinkCss = ({ isActive }) => {
    return {
      color: isActive ? "#00999e" : "#000000",
      border: isActive ? "2px solid #00999e" : "",
      borderRadius: isActive ? 8 : "",
    };
  };

  const [showGalleryOptions, setShowGalleryOptions] = useState(false);

  const [showGallery, setShowGallery] = useState(false);

  const handlePhotoGalleryClick = () => {
    setShowGallery(!showGallery);
  };

  const [showDownloadOptions, setShowDownloadOptions] = useState(false);

  const [showDownload, setShowDownload] = useState(false);

  const handleDownloadClick = () => {
    setShowDownload(!showDownload);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (appBarRef.current && !appBarRef.current.contains(event.target)) {
      setShowGalleryOptions(false);
      setShowDownloadOptions(false);
    }
  };

  const handleLinkClick = () => {
    setShowGalleryOptions(false);
    setShowDownloadOptions(false);
  };

  return (
    <AppBar
      sx={{
        background: "#fff",
        width: "100%",
        position: "relative !important",
        zIdex: "100",
      }}
    >
      <Box maxWidth="xl" ref={appBarRef}>
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            // gap: { xl: "5rem" },
            padding: { xl: "0 55px", lg: "0 55px" },
          }}
        >
          <Box
            sx={{
              maxWidth: "100%",
              overflow: "hidden",
              width: { md: "200px", sm: "180px", xs: "180px" },
            }}
          >
            <NavLink
              to={"/"}
              style={{ textDecoration: "none", color: "#000000" }}
            >
              <img
                src={LOGO}
                alt="logo img"
                style={{ width: "100%", height: "auto" }}
              />
            </NavLink>
          </Box>

          <Box
            sx={{
              color: "black",
              mt: "25px ",
              justifyContent: "space-between",
              gap: { xl: "1.5rem", sm: "" },
              zIndex: "49484",
              display: {
                xl: "screen",
                lg: "screen",
                md: "screen",
                sm: "none",
                xs: "none",
              },
            }}
          >
            <Typography
              sx={{
                padding: { md: "10px 5px", lg: "10px 5px" },
                cursor: "pointer",
              }}
            >
              <NavLink
                to={"/srtst"}
                style={{ textDecoration: "none", color: "#000000" }}
                onClick={handleLinkClick}
              >
                Home
              </NavLink>
            </Typography>
            <Typography sx={{ padding: { md: "10px 0px", lg: "10px 5px" } }}>
              |
            </Typography>
            {/*<NavLink
              to={"/srtst/about"}
              style={{ textDecoration: "none", color: "#000000" }}
               onClick={handleLinkClick}
            >
              <Typography
                sx={{
                  padding: { md: "10px 5px", lg: "10px 5px" },
                  cursor: "pointer",
                }}
              >
                About
              </Typography>
              </NavLink> */}
            <Typography
              sx={{
                padding: { md: "10px 5px", lg: "10px 5px" },
                cursor: "pointer",
              }}
            >
              About
            </Typography>
            <Typography sx={{ padding: { md: "10px 0px", lg: "10px 5px" } }}>
              |
            </Typography>
            <Box sx={{ display: "relative" }}>
              <Typography
                sx={{
                  padding: { md: "10px 5px", lg: "10px 5px", NavLinkCss },
                  cursor: "pointer",
                  color: showGalleryOptions ? "#EF5835" : "initial",
                }}
                onClick={() => {
                  setShowGalleryOptions(!showGalleryOptions);
                  setShowDownloadOptions(false);
                }}
              >
                Gallery
              </Typography>
              {showGalleryOptions && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "60px",
                    backgroundColor: "#fff",
                    width: "120px",
                    height: "auto",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                    padding: "10px",
                  }}
                >
                  <Typography sx={{ color: "#000", cursor: "pointer" }}>
                    <NavLink
                      to="/srtst/gallery/photos"
                      style={{ textDecoration: "none", color: "#000000" }}
                    >
                      Photo Gallery
                    </NavLink>
                  </Typography>
                  {/*<Typography
                    sx={{ color: "#000", cursor: "pointer", marginTop: "4px" }}
                  >
                    <NavLink
                      to="/srtst/gallery/videos"
                      style={{ textDecoration: "none", color: "#000000" }}
                    >
                      Video Gallery
                    </NavLink>
                </Typography> */}
                </Box>
              )}
            </Box>
            <Typography sx={{ padding: { md: "10px 0px", lg: "10px 5px" } }}>
              |
            </Typography>
            <Box sx={{ position: "relative" }}>
              <Typography
                sx={{
                  padding: { md: "10px 5px", lg: "10px 5px" },
                  cursor: "pointer",
                  color: showDownloadOptions ? "#EF5835" : "initial",
                }}
                onClick={() => {
                  setShowDownloadOptions(!showDownloadOptions);
                  setShowGalleryOptions(false);
                }}
              >
                Download
              </Typography>
              {/*showDownloadOptions && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "35px",
                    backgroundColor: "#fff",
                    width: "120px",
                    height: "auto",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                    padding: "10px",
                  }}
                >
                  <Typography sx={{ color: "#000", cursor: "pointer" }}>
                    <NavLink
                      to="/srtst/admitcard"
                      style={{ textDecoration: "none", color: "#000000" }}
                    >
                      Admit Card
                    </NavLink>
                  </Typography>
                </Box>
              ) */}
            </Box>
            <Typography sx={{ padding: { md: "10px 0px", lg: "10px 5px" } }}>
              |
            </Typography>
            <Typography
              sx={{
                padding: { md: "10px 5px", lg: "10px 5px" },
                cursor: "pointer",
              }}
            >
              {/*<NavLink
                to={"/srtst/samplepaper"}
                style={{ textDecoration: "none", color: "#000000" }}
                onClick={handleLinkClick}
              >
                Sample Paper
            </NavLink>*/}
              Sample Paper
            </Typography>
            <Typography sx={{ padding: { md: "10px 0px", lg: "10px 5px" } }}>
              |
            </Typography>
            <Typography
              sx={{
                padding: { md: "10px 5px", lg: "10px 5px" },
                cursor: "pointer",
              }}
            >
              {/*<NavLink
                to={"/srtst/TestCenter"}
                style={{ textDecoration: "none", color: "#000000" }}
                onClick={handleLinkClick}
              >
                Test Center
            </NavLink> */}
              Test Center
            </Typography>
            <Typography sx={{ padding: { md: "10px 0px", lg: "10px 5px" } }}>
              |
            </Typography>
            <NavLink
              to={"/srtst/result"}
              style={{ textDecoration: "none", color: "#000000" }}
              onClick={handleLinkClick}
            >
              <Typography
                sx={{
                  padding: { md: "10px 5px", lg: "10px 5px" },
                  cursor: "pointer",
                }}
              >
                Result
              </Typography>
            </NavLink>
            <Typography sx={{ padding: { md: "10px 0px", lg: "10px 5px" } }}>
              |
            </Typography>
            <Typography
              sx={{
                padding: { md: "10px 5px", lg: "10px 5px" },
                cursor: "pointer",
              }}
            >
              <NavLink
                to={"/srtst/contactus"}
                style={{ textDecoration: "none", color: "#000000" }}
                onClick={handleLinkClick}
              >
                Contact Us
              </NavLink>
            </Typography>
          </Box>

          <Box sx={{ display: { xs: "block", md: "none", sm: "block" } }}>
            <IconButton
              size="large"
              aria-label="open navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              // onClick={handleOpenNavMenu}
              onClick={handleDrawerOpen}
              color="black"
              pt="20px"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerClose}
                ModalProps={{ keepMounted: true }}
                sx={{
                  "& .MuiPaper-root": {
                    // marginLeft:"3px",
                    // alignItems:"center",
                    // display:"flex",
                    // justifyContent:"center"
                  },
                }}
              >
                <Box
                  sx={{
                    maxWidth: "100%",
                    overflow: "hidden",
                    width: { md: "200px", sm: "100px", xs: "180px" },
                    ml: "30px",
                    mt: "10px",
                  }}
                >
                  <NavLink
                    to={"/"}
                    style={{ textDecoration: "none", color: "#000000" }}
                  >
                    <img
                      src={LOGO}
                      alt="logo img"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </NavLink>
                </Box>

                <Box
                  mt={2}
                  sx={{
                    gap: "2rem",
                    flexGrow: 1,
                    display: { xl: "none", lg: "none", md: "none" },
                    width: 250,
                  }}
                >
                  <Typography>
                    <Button
                      sx={{
                        color: "black",
                        pl: "35%",
                        // fontWeight:"600",

                        fontSize: {
                          xl: "1rem",
                          lg: "1rem",
                          md: "10px",
                          sm: "10px",
                        },
                        marginTop: { xl: "", lg: "14px", md: "13px" },
                        textAlign: "center",
                        marginLeft: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "-25%",
                        },
                        textTransform: "none",
                      }}
                    >
                      <NavLink
                        to={"/srtst"}
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        Home
                      </NavLink>
                    </Button>
                  </Typography>

                  <Typography>
                    <Button
                      sx={{
                        color: "black",
                        pl: "35%",

                        fontSize: {
                          xl: "1rem",
                          lg: "1rem",
                          md: "10px",
                          sm: "10px",
                        },
                        marginTop: { xl: "", lg: "14px", md: "13px" },
                        textAlign: "center",
                        marginLeft: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "-25%",
                        },
                        textTransform: "none",
                      }}
                    >
                      {/*<NavLink
                        to={"/srtst/about"}
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        About
                    </NavLink> */}
                      About
                    </Button>
                  </Typography>

                  <Typography>
                    <Button
                      sx={{
                        color: "black",
                        pl: "35%",
                        fontSize: {
                          xl: "1rem",
                          lg: "1rem",
                          md: "10px",
                          sm: "10px",
                        },
                        marginTop: { xl: "", lg: "14px", md: "13px" },
                        textAlign: "center",
                        marginLeft: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "-25%",
                        },
                        textTransform: "none",
                      }}
                      onClick={handlePhotoGalleryClick}
                    >
                      Gallery
                    </Button>
                  </Typography>
                  {showGallery && (
                    <Typography>
                      <Button
                        sx={{
                          color: "black",
                          pl: "35%",
                          fontSize: {
                            xl: "1rem",
                            lg: "1rem",
                            md: "10px",
                            sm: "10px",
                          },
                          marginTop: { xl: "", lg: "14px", md: "13px" },
                          textAlign: "center",
                          marginLeft: {
                            xl: "0%",
                            lg: "0%",
                            md: "0%",
                            sm: "-20%",
                            xs: "10px",
                          },
                          textTransform: "none",
                        }}
                      >
                        <NavLink
                          to={"/srtst/gallery/photos"}
                          style={{ textDecoration: "none", color: "#000000" }}
                        >
                          * Photo Gallery
                        </NavLink>
                      </Button>
                    </Typography>
                  )}

                  {/*showGallery && (
                    <Typography>
                      <Button
                        sx={{
                          color: "black",
                          pl: "35%",
                          fontSize: {
                            xl: "1rem",
                            lg: "1rem",
                            md: "10px",
                            sm: "10px",
                          },
                          marginTop: { xl: "", lg: "14px", md: "13px" },
                          textAlign: "center",
                          marginLeft: {
                            xl: "0%",
                            lg: "0%",
                            md: "0%",
                            sm: "-20%",
                            xs: "10px",
                          },
                          textTransform: "none",
                        }}
                      >
                        <NavLink
                          to={"/srtst/gallery/videos"}
                          style={{ textDecoration: "none", color: "#000000" }}
                        >
                          * Video Gallery
                      </NavLink>
                      </Button>
                    </Typography>
                  )*/}

                  <Typography>
                    <Button
                      sx={{
                        // color: "black",
                        color: showDownloadOptions ? "#EF5835" : "black",
                        pl: "35%",
                        fontSize: {
                          xl: "1rem",
                          lg: "1rem",
                          md: "10px",
                          sm: "10px",
                        },
                        marginTop: { xl: "", lg: "14px", md: "13px" },
                        textAlign: "center",
                        marginLeft: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "-25%",
                        },
                        textTransform: "none",
                      }}
                      // onClick={handleDownloadClick}
                    >
                      Download
                    </Button>
                  </Typography>

                  {showDownload && (
                    <Typography>
                      <Button
                        sx={{
                          color: "black",
                          pl: "35%",
                          fontSize: {
                            xl: "1rem",
                            lg: "1rem",
                            md: "10px",
                            sm: "10px",
                          },
                          marginTop: { xl: "", lg: "14px", md: "13px" },
                          textAlign: "center",
                          marginLeft: {
                            xl: "0%",
                            lg: "0%",
                            md: "0%",
                            sm: "-20%",
                            xs: "10px",
                          },
                          textTransform: "none",
                        }}
                      >
                        <NavLink
                          to={"/srtst/admitcard"}
                          style={{ textDecoration: "none", color: "#000000" }}
                        >
                          * Admit Card
                        </NavLink>
                      </Button>
                    </Typography>
                  )}

                  <Typography>
                    <Button
                      sx={{
                        color: "black",
                        pl: "35%",
                        // fontWeight:"600",

                        fontSize: {
                          xl: "1rem",
                          lg: "1rem",
                          md: "10px",
                          sm: "10px",
                        },
                        marginTop: { xl: "", lg: "14px", md: "13px" },
                        textAlign: "center",
                        marginLeft: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "-25%",
                        },
                        textTransform: "none",
                      }}
                    >
                      {/*<NavLink
                        to={"/srtst/samplepaper"}
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        Sample Paper
                    </NavLink> */}
                      Sample Paper
                    </Button>
                  </Typography>

                  <Typography>
                    <Button
                      sx={{
                        color: "black",
                        pl: "35%",
                        // fontWeight:"600",

                        fontSize: {
                          xl: "1rem",
                          lg: "1rem",
                          md: "10px",
                          sm: "10px",
                        },
                        marginTop: { xl: "", lg: "14px", md: "13px" },
                        textAlign: "center",
                        marginLeft: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "-25%",
                        },
                        textTransform: "none",
                      }}
                    >
                      {/*<NavLink
                        to={"/srtst/TestCenter"}
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        Test Center
                    </NavLink> */}
                      Test Center
                    </Button>
                  </Typography>

                  <Typography>
                    <Button
                      sx={{
                        color: "black",
                        pl: "35%",
                        // fontWeight:"600",

                        fontSize: {
                          xl: "1rem",
                          lg: "1rem",
                          md: "10px",
                          sm: "10px",
                        },
                        marginTop: { xl: "", lg: "14px", md: "13px" },
                        textAlign: "center",
                        marginLeft: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "-25%",
                        },
                        textTransform: "none",
                      }}
                    >
                      <NavLink
                        to={"/srtst/result"}
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        Result
                      </NavLink>
                    </Button>
                  </Typography>

                  <Typography>
                    <Button
                      sx={{
                        color: "black",
                        pl: "35%",
                        // fontWeight:"600",

                        fontSize: {
                          xl: "1rem",
                          lg: "1rem",
                          md: "10px",
                          sm: "10px",
                        },
                        marginTop: { xl: "", lg: "14px", md: "13px" },
                        textAlign: "center",
                        marginLeft: {
                          xl: "0%",
                          lg: "0%",
                          md: "0%",
                          sm: "-25%",
                        },
                        textTransform: "none",
                      }}
                    >
                      <NavLink
                        to={"/srtst/contactus"}
                        style={{ textDecoration: "none", color: "#000000" }}
                      >
                        Contact Us
                      </NavLink>
                    </Button>
                  </Typography>
                </Box>
              </Drawer>
            </Menu>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}

export default ResponsiveAppBar;
