import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import SpSimple from '../../assets/Icons/SPSimple.svg';
import SpHover from '../../assets/Icons/SPHover.svg';
import { Icon } from '@iconify/react/dist/iconify.js';

const SamplePaperCard = () => {
    const [hovered, setHovered] = useState(false);

    return (
        <Box
            sx={{
                height: "100px",
                width: "280px",
                backgroundColor: "#F5F5F5",
                display: "flex",
                borderRadius: "8px",
                border: hovered ? "2px solid #EA5806" : "none",
                alignItems: "center",
                padding: "0 20px"

            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Box sx={{ width: "60px" }}>

                {hovered ? (
                    <img src={SpHover} alt='' width={"100%"} />
                ) : (
                    <img src={SpSimple} alt='' width={"100%"} />
                )}
            </Box>
            <Box sx={{ paddingLeft: "10px" }}>

                <Typography variant="h5" sx={{ fontSize: "20px", fontWeight: 550 }}>
                    Class V
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>

                    <Typography variant="body2" sx={{ paddingRight: "5px" }}>
                        Sample Paper
                    </Typography>
                    <Icon icon="formkit:download" color='grey' fontSize={"13px"} />
                </Box>
            </Box>
        </Box>
    );
};

export default SamplePaperCard;
