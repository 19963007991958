import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const OurProgramCard = ({ data }) => {
  const navigate = useNavigate();

  const handleClick = (value) => {
    if (value === "Education") {
      navigate("/srtst");
    } else {
      return false;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: { md: "center", sm: "start", xs: "start" },
        justifyContent: "center",
        pl: { xl: "0px", sm: "10px", xs: "10px" },
      }}
    >
      <Card
        sx={{
          borderRadius: "50%",
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "2px solid #EA5806",
          cursor: data?.title == "Education" ? "pointer" : "",
        }}
        onClick={() => handleClick(data?.title)}
      >
        <img src={data?.image} alt="" width={"100%"} />
      </Card>
      <Box sx={{ ml: "10px" }} onClick={() => handleClick(data?.title)}>
        <Typography
          sx={{
            fontSize: { xl: "30px", sm: "25px", xs: "px" },
            color: "#EA5806",
            fontWeight: "700",
            cursor: data?.title == "Education" ? "pointer" : "",
          }}
        >
          {data?.title}
          <Typography
            sx={{
              fontSize: { xl: "16px", sm: "15px", xs: "12px" },
              color: "#008035",
              fontWeight: "500",
            }}
          >
            {data?.description}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default OurProgramCard;
