import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import slider1 from "../../assets/Images/homeSlider4_1.jpg";

const WhatisSrtst = () => {
  // const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        backgroundColor: "#F6F6F6",
        padding: { xs: "20px 0", sm: "30px 0", md: "50px 0", lg: "50px 0" },
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"}>
            <Box
              sx={{
                width: "85%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
                boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.4)",
              }}
            >
              <img src={slider1} alt="img" width="100%" />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} display={"flex"} justifyContent={"center"}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Container>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "34px",

                    color: "#EA5806",
                    fontWeight: "550",
                  }}
                >
                  What is SRTST?
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    margin: "10px 0",
                    color: "#4e4e4e",
                  }}
                >
                  Shri Radhey Shyam Tripathi Scholarship Test is a special
                  initiative to enable economically disadvantaged and
                  academically talented students to pursue their education
                  without financial constraints by providing financial
                  assistance, guidance and empowering them to contribute to the
                  progress of their Village, District and Nation.
                </Typography>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WhatisSrtst;
