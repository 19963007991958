import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import VideoGalleryData from '../../json/VideoGalleryData';
import { Box, Container, Typography } from "@mui/material";

export default function VideoGallery() {
  return (
    <Grid mb={5}>
      <Box textAlign="center">
        <Box>
          <Typography className='InterFont' sx={{ color: "#EA5806", fontSize: "25px" }}>Gallery</Typography>
        </Box>
        <Box>
          <Typography className='InterFont' sx={{ fontSize: "25px", fontWeight: "600" }}>Video Gallery</Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: { md: "0 200px", sm: "0 50px" } }}>

        <Grid container spacing={2}>
          {VideoGalleryData.map((item, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              display={"flex"}
              justifyContent={"center"}
            >
              <Box
                sx={{

                  height: "300px",
                  width: "300px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >

                <video width="100%" controls>
                  <source src={item.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Grid>
  );
}
