import React from "react";
import Navbar from "../srtst/Navbar/Navbar";

import { Box, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import ContactMain from "./ContactMain";
import FooterComponent from "../home/Footer/Footer";

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <ContactMain />
      <Container>
        <Box sx={{ marginBottom: "20px" }}>
          <iframe
            title="Unique Title"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.851736084177!2d77.32850437409027!3d28.544175838090162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce42ac207209d%3A0x94dbc78a3c29065e!2sTradex%20Tower%20Noida%20sector%20125!5e0!3m2!1sen!2sin!4v1713945290316!5m2!1sen!2sin"
            width="100%"
            height="400"
            style={{ border: 0, marginTop: "20px" }}
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </Box>
      </Container>
      <FooterComponent />
    </>
  );
};

export default ContactUsPage;
