import { Box } from '@mui/material'
import React from 'react'
import VisionPage from './home/VisionPage'
import HomeBanner from './home/home_banner'
import Navbar from './home/Navbar/Navbar'
import Footer from './home/Footer/Footer'
import OurProgram from './home/OurProgram'
import OurImpact from './home/OurImpact'
import GetInvolved from '../components/components/GetInvolved'

const HomePage = () => {
    return (
        <>
            <Box>
                <Navbar />
                <HomeBanner />
                <OurImpact />
                <OurProgram />
                <VisionPage />
                <GetInvolved marginBottom={"-35px"} />
                <Footer />

            </Box>
        </>
    )
}

export default HomePage
