import { Box } from '@mui/material'
import React from 'react'
import Navbar from './../Navbar/Navbar'
import Footer from './../../home/Footer/Footer'
import Detail from './details'
import HomeBanner from "../home_banner"

const AboutHome = () => {
  return (
    <>
    <Box>
        <Navbar />
        <HomeBanner />
        <Detail />
        <Footer />
    </Box>
</>
  )
}

export default AboutHome
