import { Box, Card, Typography } from "@mui/material";
import React, { useState } from "react";
import icon from "../../assets/Icons/benfitIcon.svg";
import { useNavigate } from "react-router";
import { Icon } from "@iconify/react";
import "./benfitCard.css";

const BenefitCard = ({ data }) => {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    // window.scrollTo(0, 0);
    // navigate(path);
  };

  const [hoveredStep, setHoveredStep] = useState(null);

  return (
    <>
      <Box>
        <Card
          onClick={() => handleNavigation(data?.path)}
          sx={{
            width: "240px",
            height: "240px",
            borderTopWidth: "50%",
            "&:hover": {
              backgroundColor: "#EA5806",
              color: "white",
              zIndex: 1,
              "& .css-1rq26sm-MuiTypography-root": {
                color: "#ffffff",
              },
              "& .title": {
                color: "#ffffff",
              },
              "& .description": {
                color: "#ffffff",
              },
            },
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
            position: "relative",
            zIndex: 1,
            overflow: "visible",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          onMouseEnter={() => setHoveredStep(1)}
          onMouseLeave={() => setHoveredStep(null)}
          className="pseudo-partial-border"
        >
          <Box padding={1} sx={{ display: "flex", justifyContent: "center" }}>
            <img alt="" src={data?.image} width={"80%"} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "0px 20px",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "600", color: "#008035" }}
              className="title"
            >
              {data?.title}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "0px 20px",
              alignItems: "center",
            }}
          >
            <Typography
              variant="caption"
              sx={{ fontWeight: "500", color: "#717171" }}
              className="description"
            >
              {data?.Desc}
            </Typography>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default BenefitCard;
