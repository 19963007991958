import React from "react";
import { Button, Stack, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const GetInvolved = ({ top, bottom, marginBottom, marginTop }) => {
  return (
    <Container sx={{ mb: marginBottom ?? "60px", mt: marginTop }}>
      <Stack
        sx={{
          border: "3px solid #EA5806",
          background: "#ffffff",
          padding: { xm: "15px", md: "20px", xs: "15px", lg: "30px" },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "10px",
          gap: { xm: "2rem", md: "2rem", xs: "2rem", lg: "2rem" },
          marginLeft: {
            xs: "0px",
            sm: "40px",
            md: "30px",
            lg: "0px",
            xl: "3px",
          },
          marginRight: {
            xs: "0px",
            sm: "40px",
            md: "30px",
            lg: "0px",
            xl: "3px",
          },
          position: "relative",
          top: { top },
          bottom: { bottom },
          flexDirection: { xs: "col", sm: "row", md: "row", lg: "row" },
        }}
      >
        <Stack>
          <Typography
            sx={{
              fontSize: { xs: "18px", sm: "24px", md: "26px", lg: "38px" },
              fontWeight: 700,
              paddingLeft: "15px",
              textAlign: { xs: "center", sm: "left", md: "left", lg: "left" },
            }}
          >
            Get Involved
          </Typography>
          <Typography sx={{ pl: 2 }} variant="subtitle2">
            Not sure where to get started? We will help you get it done right
            and fast.
          </Typography>
        </Stack>

        <Stack>
          {/* <Link to="/contact" className="custom-link"> */}
          <Button
            variant="contained"
            fullWidth
            sx={{
              "&:hover": {
                bgcolor: "#008035",
              },
              backgroundColor: "#008035",
              width: "200px",
              height: { sm: "40px", md: "45px", xs: "30px", lg: "50px" },
              fontWeight: 700,
              borderRadius: "25px",

              fontSize: { xm: "10px", md: "15px", xs: "13px", lg: "15px" },
              textTransform: "none",
            }}
          >
            <Link
              to={"/contactus"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Contact Us
            </Link>
          </Button>
          {/* </Link> */}
        </Stack>
      </Stack>
    </Container>
  );
};

export default GetInvolved;
