

import { Box, Container, Grid, Link, Typography } from "@mui/material";
import React from "react";
import White from "../../../assets/Icons/23568665955 1.png";
import Instagram from "../../../assets/Icons/Social Icons.png";
import Dribble from "../../../assets/Icons/Social Icons (3).png";
import Twitter from "../../../assets/Icons/Social Icons (1).png";
import Youtube from "../../../assets/Icons/Social Icons (2).png"

const FooterComponent = ({ margintop, paddingtop }) => {
  return (
    <Box  sx={{ background: "rgba(56, 55, 58, 1)", pb: "25px", paddingTop: "40px" }}>
      {/* <Container maxWidth='xxl'> */}
        <Box className="box2" >
          <Grid spacing={5} item md={6} sm={6} >
            <Box

              sx={{
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                padding: {
                  xl: "0rem",
                  lg: "10px",
                  md: "0px",
                  sm: "0px",
                  xs: "0px",
                },
                textAlign: { xs: "center" },
                gap: { xl: "13%", lg: "9%", md: "10%",xs:"5%"},
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "row",
                  sm: "column",
                  xs: "column",
                },
                paddingBottom: "5%",
              }}
            >
              <Box>
                <Typography
                  mt={3}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xl: "start",
                      lg: "start",
                      md: "",
                      sm: "center",
                      xs: "center",
                    },
                  }}
                >
                  <img
                    src={White}
                    alt="White"
                    style={{ maxWidth: "100%", height: "50px" }}
                  />
                </Typography>
                <Typography
                  className="box2"
                  mt={5.5}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Copyright © 2020 Nexcent ltd.
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  All rights reserved
                </Typography>
                <Box
                  mt={5}
                  sx={{
                    display: "flex", flexDirection:"row",justifyContent:"center","& > img": {
      marginRight: "10px", // Adjust this value to your preference
    },
                    
                  }}
                >
                  <img src={Instagram} alt="" />
                  <img src={Dribble} alt="" />
                  <img src={Twitter} alt="" />
                  <img src={Youtube} alt="" />

                </Box>
              </Box>
              <Box>
                <Typography

                  sx={{
                    color: "#fff",
                    fontSize: { xl: "1.5rem", lg: "1.5rem", md: "1rem", sm: "1.5rem", xs: "1.5rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                    mt: { md: "1.5em", xs: "2rem" }
                  }}
                >
                  Company
                </Typography>
                <Typography
                  mt={5}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  About Us
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Blog
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Contact us
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Pricing
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Testimonials
                </Typography>
              </Box>
              <Box>
                <Typography

                  sx={{
                    color: "#fff",
                    fontSize: { xl: "1.5rem", lg: "1.5rem", md: "1rem", sm: "1.5rem", xs: "1.5rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                    mt: { md: "1.5em", xs: "2rem" }
                  }}
                >
                  Support
                </Typography>
                <Typography
                  mt={5}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  <Link href={"/"} sx={{ color: "#fff", textDecoration: "none" }}>Help center{" "}</Link>
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Terms of service
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Legal
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Privacy policy
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Status
                </Typography>
              </Box>
              <Box>
                <Typography

                  sx={{
                    color: "#fff",
                    fontSize: { xl: "1.5rem", lg: "1.5rem", md: "1rem", sm: "1.5rem", xs: "1.5rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                    mt: { md: "1.5em", xs: "2rem" }
                  }}
                >
                  Other Link
                </Typography>
                <Typography
                  mt={5}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Help Center
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Terms of service
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Legal
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Privacy policy
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Status
                </Typography>
              </Box>
            </Box>

            {/* <Box sx={{ marginTop: { lg: "0%", xs: "0rem" }, width: "100%" }}>
          <Typography  sx={{ display: "flex", justifyContent: "center" }}>
            <img
              src={Group}
              alt=""
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Typography>
        </Box> */}


          </Grid>
        </Box>
      {/* </Container> */}
    </Box>
  );
};

export default FooterComponent;
