const OurImpactData = [
  {
    value: "5",
    title: "Lakh+",
    description:
      "Transforming Lives: Over 5 Lac children and their families are positively impacted each year by our dedicated efforts.",
  },
  {
    value: "5K+",
    title: "Villages",
    description:
      "Community Reach: We've reached out to over 500 villages, bringing essential services and support where it's needed most.",
  },
  {
    value: "100+",
    title: "Projects",
    description:
      "Multi-Faceted Initiatives: With over 100 projects, we're actively advancing education, healthcare, and women's empowerment.",
  },
  {
    value: "",
    title: "Empowering Communities",
    description:
      "Through sustainable initiatives, we've empowered individuals to seize control of their futures, fostering self-reliance and resilience.",
  },
];
export default OurImpactData;
